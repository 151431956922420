import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from 'web/app/models/user.model';
import { environment } from 'web/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly baseUri: string;

  constructor(private http: HttpClient) {
    this.baseUri = environment.apiUri;
  }

  refresh(): Observable<any> {
    return this.http.get(this.baseUri + '/v2/auth/refresh');
  }

  checkLoginStatus(): Observable<User> {
    return this.http.get(this.baseUri + '/user/me').pipe(map((response: User) => response));
  }

  logout(): Observable<any> {
    return this.http.get(this.baseUri + '/v2/auth/logout');
  }

  clearAppSession(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('facebookToken');
    localStorage.removeItem('currentUser');
  }
}
