<form
  class="toolbar"
  [formGroup]="filterForm"
  [exclude]="'.mat-select-panel,.mat-option,.mat-calendar,.filters, .cdk-overlay-backdrop'"
  [excludeBeforeClick]="true"
  (clickOutside)="closeFilters($event)"
>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="header">{{ filter?.viewAll ? 'All' : 'My' }} Fosters</div>

    <div fxFlex="40" fxHide.xs="true" fxLayoutAlign="start center">
      <mat-icon class="search-icon">search</mat-icon>
      <input placeholder="Search..." type="text" [formControl]="searchControl" />
    </div>
    <div fxLayoutAlign="space-between center">
      <h4>{{ total }} Fosters</h4>
      <mat-slide-toggle class="view-all" fxHide.xs="true" formControlName="viewAll"
        >View All</mat-slide-toggle
      >
      <button
        type="button"
        mat-button
        color="primary"
        class="filter-button"
        mat-icon-button
        (click)="toggleShowFilters()"
      >
        <mat-icon>filter_list</mat-icon>
      </button>
    </div>
    <div class="filters" *ngIf="showFilters">
      <mat-progress-bar *ngIf="loading" color="white" mode="indeterminate"></mat-progress-bar>
      <div class="container" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start center">
        <app-advanced-filter
          *ngIf="advancedFilter"
          fxFlex
          [isAdmin]="isAdmin"
          [advancedFilters]="advancedFilters"
          (changeAdvancedFilters)="advancedFilterChange($event)"
        >
        </app-advanced-filter>

        <app-simple-filter
          *ngIf="!advancedFilter"
          fxFlex
          [isAdmin]="isAdmin"
          [filter]="filter"
          [filterForm]="filterForm"
          [search]="search"
          (changeFilter)="changeSimpleFilter($event)"
          (changeSearch)="changeSearchEvent($event)"
        >
        </app-simple-filter>

        <div
          fxLayout="column"
          fxLayout.gt-lg="row-reverse"
          fxLayoutAlign="start center"
          fxLayoutAlign.gt-lg="end center"
          ngClass.lt-md="top-20"
        >
          <button
            type="button"
            class="export-button"
            *ngIf="isAdmin && !advancedFilter"
            mat-button
            (click)="exportAll.emit()"
          >
            Export
          </button>
          <button type="button" mat-button (click)="toggleAdvancedFilters()">
            {{ advancedFilter ? 'Simple Filters...' : 'Advanced Filters...' }}
          </button>
          <div class="bottom-20"></div>
          <button mat-flat-button type="button" (click)="toggleShowFilters()">Done</button>
        </div>
      </div>
    </div>
  </div>
</form>
