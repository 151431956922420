<div class="toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="header">Bulk Update</div>
  <button
    type="button"
    class="button-margin-left"
    mat-flat-button
    color="accent"
    (click)="save()"
    [disabled]="form.invalid || form.pristine || (processing$ | async)"
  >
    Save
  </button>
</div>
<div class="page-content">
  <form [formGroup]="form">
    <mat-card>
      <div class="text-center bottom-40">
        <h3 class="bottom-0">
          <span class="count">{{ (selected$ | async).length }}</span> fosters will be updated:
        </h3>
        <p fxShow [fxShow.gt-sm]="false">{{ names }}</p>
        <ul fxHide [fxHide.gt-sm]="false" fxLayout="row wrap" fxLayoutAlign="center center">
          <li
            *ngFor="let foster of selected$ | async; trackBy: trackByFn"
            class="bottom-10 foster-row"
          >
            <img *ngIf="foster.thumbUri" [src]="baseUri + foster.thumbUri" alt="Foster Profile" />
            <img
              *ngIf="!foster.thumbUri && foster.species !== Species.Cat"
              src="assets/dog.png"
              alt="Dog Placeholder"
            />
            <img
              *ngIf="!foster.thumbUri && foster.species === Species.Cat"
              src="assets/cat.png"
              alt="Cat Placeholder"
            />
            <div>{{ foster.name }}</div>
          </li>
        </ul>
      </div>
      <div *ngIf="showFosterInfo">
        <h3 class="bottom-0">Foster Info</h3>
        <app-base-foster-info [form]="form" [currentUser]="currentUser"></app-base-foster-info>
        <mat-form-field appearance="standard">
          <mat-label>Notes</mat-label>
          <textarea matInput formControlName="notes" cdkTextareaAutosize [cdkAutosizeMinRows]="3"></textarea>
          <mat-hint>Any notes will be <i>added</i> to each foster's notes.</mat-hint>
        </mat-form-field>
        <h3 class="bottom-0">Medical Info</h3>
        <app-medical-info
          [form]="form"
          [species]="species"
          [birthDate]="birthDate"
          [hideMicrochip]="true"
        ></app-medical-info>
      </div>
      <div *ngIf="showAdoptionInfo" class="top-30">
        <h3>Adoption Info</h3>
        <div fxLayout="column" fxLayout.gt-xs="row">
          <mat-slide-toggle formControlName="readyForAdoption">Ready For Adoption</mat-slide-toggle>
          <mat-slide-toggle
            *ngIf="currentUser?.isAdmin || currentUser?.isAdoptionCoordinator"
            formControlName="isAdopted"
            class="bottom-5"
            >Adopted</mat-slide-toggle
          >
        </div>
      </div>
    </mat-card>
  </form>
</div>
