import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import Session from 'supertokens-web-js/recipe/session';

import { User } from 'web/app/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private snackbar: MatSnackBar) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.url?.[0]?.path !== 'auth/verify') {
      Session.doesSessionExist()
        .then((exists) => {
          if (!exists) {
            this.router.navigate(['/login']).then();
            return false;
          }
          return true;
        })
        .catch((err) => {});
    }

    const user = User.getCached();
    if (route.data.adminOnly) {
      if (user.isAdmin) {
        return true;
      }
      this.snackbar.open("Oops! Looks like you don't have permission to do that.", 'Dismiss');
      return false;
    }
    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(childRoute, state);
  }
}
