import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  createCode,
  consumeCode,
  clearLoginAttemptInfo,
  resendCode,
  getLoginAttemptInfo,
} from 'supertokens-web-js/recipe/passwordless';

import { RbSnackbar } from 'web/app/core/rb-snackbar';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form?.submitted;
    return !!(control?.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<boolean>();
  code: string;
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

  otpFormControl = new FormControl('', [Validators.required]);

  matcher = new MyErrorStateMatcher();

  loginForm = this.formBuilder.group({
    email: '',
  });

  otpForm = this.formBuilder.group({
    otp: '',
  });

  waitingOnOTP = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private snackbar: RbSnackbar,
    private logger: NGXLogger,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.code = params.code;
    });

    this.matcher.isErrorState(this.emailFormControl, null);

    this.hasInitialOTPBeenSent().then();
  }

  async startOver() {
    await clearLoginAttemptInfo();
    this.waitingOnOTP = false;
    this.emailFormControl.reset();
    this.otpFormControl.reset();
  }

  async resendOTP() {
    try {
      const response = await resendCode();

      if (response.status === 'RESTART_FLOW_ERROR') {
        // this can happen if the user has already successfully logged in into
        // another device whilst also trying to login to this one.

        // we clear the login attempt info that was added when the createCode function
        // was called - so that if the user does a page reload, they will now see the
        // enter email / phone UI again.
        await this.startOver();
      } else {
        // OTP resent successfully.
        this.snackbar.success('Temporary code resent successfully');
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        // window.alert(err.message);
      } else {
        this.snackbar.error('Oops! Something went wrong.');
      }
    }
  }

  async otpSubmit() {
    const otp = this.otpFormControl.value;

    try {
      const response = await consumeCode({
        userInputCode: otp || '',
      });

      if (response.status === 'OK') {
        // we clear the login attempt info that was added when the createCode function
        // was called since the login was successful.
        await this.startOver();
        window.location.reload();

        if (response.createdNewRecipeUser && response.user.loginMethods.length === 1) {
          // user sign up success
        } else {
          // user sign in success
        }
        // window.location.assign('/home');
      } else if (response.status === 'INCORRECT_USER_INPUT_CODE_ERROR') {
        // the user entered an invalid OTP

        this.snackbar.error(
          'Wrong OTP! Please try again. Number of attempts left: ' +
            (response.maximumCodeInputAttempts - response.failedCodeInputAttemptCount)
        );
        this.otpFormControl.reset();
      } else if (response.status === 'EXPIRED_USER_INPUT_CODE_ERROR') {
        // it can come here if the entered OTP was correct, but has expired because
        // it was generated too long ago.
        this.snackbar.error('Old OTP entered. Please regenerate a new one and try again');
        this.otpFormControl.reset();
      } else {
        // this can happen if the user tried an incorrect OTP too many times.
        // or if it was denied due to security reasons in case of automatic account linking

        // we clear the login attempt info that was added when the createCode function
        // was called - so that if the user does a page reload, they will now see the
        // enter email / phone UI again.
        // eslint-disable-next-line no-void
        await this.startOver();
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        // window.alert(err.message);
      } else {
        this.snackbar.error('Oops! Something went wrong.');
      }
    }
  }

  login(): void {
    const email = this.emailFormControl.value;

    if (!email) {
      return;
    }

    createCode({
      email,
    })
      .then((response) => {
        if (response.status === 'SIGN_IN_UP_NOT_ALLOWED') {
          console.log('sign in/up not allowed');
          // this can happen due to automatic account linking. See that section in our docs.
        } else {
          // Magic link sent successfully.
        }
      })
      .catch((err) => {
        // this may be a custom error message sent from the API by you,
        // or if the input email / phone number is not valid.
        this.snackbar.error('Oops! Something went wrong.');
        this.logger.error(err);
      });

    this.waitingOnOTP = true;
  }

  async hasInitialOTPBeenSent() {
    console.log(await getLoginAttemptInfo());
    this.waitingOnOTP = (await getLoginAttemptInfo()) !== undefined;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
