import { BehaviorSubject } from 'rxjs';

import { ScanMessage } from './scanner';

export const ReadyMsg = 'ready';

export enum WorkerState {
  Loading = 0,
  Ready = 1,
}

export class PromiseWorker {
  workerState = new BehaviorSubject<WorkerState>(WorkerState.Loading);

  private readonly worker;

  constructor(worker: Worker) {
    this.worker = worker;

    this.worker.onmessage = ({ data }: { data: 'ready' }) => {
      if (this.workerState.value === WorkerState.Loading) {
        if (data === 'ready') {
          this.workerState.next(WorkerState.Ready);
        }
      }
    };
  }

  async sendMessage<T extends ScanMessage>(message: T['message']): Promise<T['response']> {
    return new Promise((resolve) => {
      this.worker.onmessage = ({ data }: { data: T['response'] }) => {
        resolve(data);
      };
      this.worker.postMessage(message);
    });
  }

  terminate(): void {
    this.worker.terminate();
  }
}
