<input
  type="file"
  #file
  class="file-button"
  accept="image/*,.doc,.docx,.pdf"
  (change)="onFileSelected()"
  multiple
/>
<ngx-file-drop
  (onFileDrop)="dropped($event)"
  [disabled]="true"
  [class.disabled]="disabled"
  [dropZoneClassName]="'file-upload'"
  [contentClassName]="'file-upload-drop-z'"
  [multiple]="false"
  accept="image/*,.doc,.docx,.pdf"
  directory="false"
  (click)="!disabled && selectFile()"
>
  <ng-template ngx-file-drop-content-tmp>
    <div class="file-upload-text">
      <div>
        <b>
          <span fxShow.xs fxHide.gt-sm>Tap</span>
          <span fxHide.sm fxHide.xs fxShow.gt-sm>Click</span>
          to Upload
        </b>
        <br />
        <div class="help-text">
          For best results:
          <small
            ><br />Lay the document flat<br />Use a dark background<br />Try turning on the camera
            flash</small
          >
        </div>
      </div>
    </div>
  </ng-template>
</ngx-file-drop>
