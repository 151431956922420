<span fxLayout="row" fxLayoutAlign="center center" class="status-container">
  <button
    mat-button
    aria-label="See Save Status"
    [matMenuTriggerFor]="menu"
    #tooltip="matTooltip"
    matTooltip="See Save Status"
  >
    <ng-container [ngSwitch]="status">
      <mat-icon *ngSwitchCase="SaveStatusEnum.Unsaved" color="warn">cloud_queue</mat-icon>
      <mat-icon *ngSwitchCase="SaveStatusEnum.Saving" class="saving">sync</mat-icon>
      <mat-icon *ngSwitchCase="SaveStatusEnum.Saved" fontSet="material-icons-outlined">
        cloud_done
      </mat-icon>
      <mat-icon *ngSwitchCase="SaveStatusEnum.UpToDate" fontSet="material-icons-outlined">
        cloud_done
      </mat-icon>
    </ng-container>
    <span *ngIf="status === SaveStatusEnum.Saving" class="status-text saving">Saving...</span>
    <span *ngIf="status === SaveStatusEnum.Saved" class="status-text">Saved!</span>
  </button>
  <mat-menu #menu="matMenu" class="save-status-menu text-center">
    <div [ngSwitch]="status" class="menu-container">
      <div *ngSwitchCase="SaveStatusEnum.Unsaved">
        <p>Save could not be completed.<br />Please try again.</p>
        <button mat-flat-button color="accent" (click)="save.emit()">Save</button>
      </div>
      <p *ngSwitchCase="SaveStatusEnum.Saving">Save in progress...</p>
      <div *ngSwitchCase="SaveStatusEnum.Saved">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon fontSet="material-icons-outlined" color="accent">cloud_done</mat-icon>
          <p>All changes have been saved.</p>
        </div>
        <p *ngIf="updatedBy || updatedAt" class="audit">
          Last updated<span *ngIf="updatedBy"> by {{ updatedBy }}</span
          ><span> on {{ updatedAt | date: 'short' }}.</span>
        </p>
      </div>
      <div *ngSwitchCase="SaveStatusEnum.UpToDate">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon fontSet="material-icons-outlined" color="accent">cloud_done</mat-icon>
          <p>Auto-save is watching for changes.</p>
        </div>
        <p *ngIf="updatedBy || updatedAt" class="audit">
          Last updated<span *ngIf="updatedBy"> by {{ updatedBy }}</span
          ><span> on {{ updatedAt | date: "M/d/yy 'at' h:mm a" }}.</span>
        </p>
      </div>
      <p *ngSwitchDefault>Save status unknown.</p>
    </div>
  </mat-menu>
</span>
