<div
  class="grid-tile foster-card"
  fxLayoutAlign="center center"
  [class.disabled]="disableClick"
  [class.selected]="selected"
>
  <div class="image-container" fxLayoutAlign="center center" [class.placeholder]="!foster.thumbUri">
    <div class="title">
      <h1 class="padding-bottom">
        {{ foster.name }}
      </h1>
      <h2 *ngIf="showUserName && foster.alternateNames.length">({{ foster.alternateNames }})</h2>
      <h2 *ngIf="showUserName && foster.user">
        {{ foster.user.firstName }} {{ foster.user.lastName }}
      </h2>
    </div>
    <img
      class="foster-thumb"
      [class.deceased]="foster.deceasedDate"
      *ngIf="foster.thumbUri"
      alt="Photo of {{ foster.name }}"
      [src]="baseUri + foster.thumbUri"
    />
    <img
      class="foster-thumb blurhash"
      id="{{ foster.id }}"
      [class.deceased]="foster.deceasedDate"
      *ngIf="foster.thumbUri"
      [src]="blurhashUrl"
      alt="Foster Profile"
    />
    <img
      *ngIf="!foster.thumbUri && foster.species !== species.Cat"
      src="assets/dog.png"
      class="placeholder-image"
      alt="Dog placeholder"
    />
    <img
      *ngIf="!foster.thumbUri && foster.species === species.Cat"
      src="assets/cat.png"
      class="placeholder-image"
      alt="Cat placeholder"
    />
  </div>
  <div *ngIf="canSelect" class="select-button-overlay">
    <div class="icon-background"></div>
    <mat-icon class="select-button" shortPress (onShortPress)="handleSelect($event)"
      >check_circle</mat-icon
    >
  </div>
</div>
