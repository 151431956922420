<div [fxShow.xs]="!editing" fxHide.gt-xs [ngClass]="last ? 'bottom-10' : 'bottom-5'">
  <div fxLayout="row" fxLayoutAlign="start center">
    <mat-icon *ngIf="givenDate?.value && !givenDate?.invalid" alt="" class="check-icon"
      >check</mat-icon
    >
    <img
      *ngIf="!givenDate?.value || givenDate?.invalid"
      width="24px"
      src="assets/syringe.svg"
      alt=""
      class="syringe-icon"
    />

    <div fxFlex>
      <div>
        {{ vaccine?.name }}
        <span *ngIf="givenDate?.value">given {{ givenDate.value | date: 'shortDate' }}</span>
        <span *ngIf="!givenDate?.value">due {{ dueDate.value | date: 'shortDate' }}</span>
      </div>
      <div style="font-size: 80%; color: #777">{{ clinic?.value }}</div>
    </div>

    <button
      type="button"
      color="primary"
      [disabled]="disabled"
      mat-icon-button
      (click)="editing = true"
    >
      <mat-icon style="margin-bottom: 2px">edit</mat-icon>
    </button>
  </div>
</div>

<div
  [formGroup]="vaccination"
  [fxHide.xs]="!editing"
  fxLayout="row wrap"
  fxLayoutAlign="start center"
  fxLayoutGap="0px"
  fxLayoutGap.gt-xs="1rem"
  [ngClass.xs]="last ? 'bottom-30' : 'bottom-5'"
>
  <mat-icon fxHide.xs *ngIf="givenDate?.value && !givenDate?.invalid" alt="" class="check-icon"
    >check</mat-icon
  >
  <img
    *ngIf="!givenDate?.value || givenDate?.invalid"
    fxHide.xs
    width="24px"
    src="assets/syringe.svg"
    alt=""
    class="syringe-icon"
  />

  <div
    fxFlexOrder="0"
    fxFlex="100"
    fxFlex.xs="auto"
    fxFlex.gt-xs="24"
    fxFlex.gt-sm="21"
    fxFlex.gt-md="16"
  >
    <mat-form-field appearance="standard">
      <mat-label>Vaccine</mat-label>
      <mat-select formControlName="vaccineId">
        <mat-option *ngFor="let v of vaccines" [value]="v.id">{{ v.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div
    *ngIf="!givenDate?.value || givenDate?.invalid"
    fxFlexOrder="2"
    fxFlex="calc(50% - 0.5rem)"
    fxFlex.gt-xs="22"
    fxFlex.gt-sm="19"
    fxFlex.gt-md="14"
    class="due-date"
  >
    <mat-form-field appearance="standard">
      <mat-label>Due</mat-label>
      <input
        matInput
        [matDatepicker]="dueDatePicker"
        formControlName="dueDate"
        [min]="minDate"
        [max]="fourYears"
      />
      <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #dueDatePicker></mat-datepicker>
      <mat-error *ngIf="dueDate.invalid">Please enter a valid date</mat-error>
    </mat-form-field>
  </div>
  <div
    fxFlexOrder="3"
    fxFlex="calc(50% - 0.5rem)"
    fxFlex.gt-xs="22"
    fxFlex.gt-sm="19"
    fxFlex.gt-md="14"
  >
    <mat-form-field appearance="standard">
      <mat-label>Given On</mat-label>
      <input
        matInput
        [matDatepicker]="givenDatePicker"
        formControlName="givenDate"
        [min]="minDate"
        [max]="fourYears"
      />
      <mat-datepicker-toggle matSuffix [for]="givenDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #givenDatePicker></mat-datepicker>
      <mat-error *ngIf="givenDate?.invalid">Please enter a valid date</mat-error>
    </mat-form-field>
  </div>
  <div fxFlexOrder="4" fxFlex="100" fxFlex.gt-xs="22" fxFlex.gt-md="17">
    <mat-form-field appearance="standard">
      <mat-label>Clinic</mat-label>
      <input matInput formControlName="clinic" />
    </mat-form-field>
  </div>
  <button
    fxFlexOrder.xs="1"
    fxFlexOrder.gt-xs="5"
    type="button"
    [disabled]="disabled"
    mat-icon-button
    color="warn"
    (click)="confirmDelete()"
  >
    <mat-icon>delete</mat-icon>
  </button>
</div>
