import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'web/app/auth/auth-guard';

import { BulkUpdateComponent } from './bulk-update/bulk-update.component';
import { FosterDetailComponent } from './foster-detail/foster-detail.component';
import { FosterListComponent } from './foster-list/foster-list.component';

const routes: Routes = [
  { path: 'list', component: FosterListComponent, canActivate: [AuthGuard] },
  { path: 'add', component: FosterDetailComponent, canActivate: [AuthGuard] },
  { path: 'detail/:id', component: FosterDetailComponent, canActivate: [AuthGuard] },
  { path: 'bulk-update', component: BulkUpdateComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FosterRoutingModule {}
