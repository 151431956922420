<mat-dialog-content fxLayout="column">
  <h1>Fecal Follow-up</h1>
  <div ngClass.xs="text-center">
    <p>
      You should bring in a new fecal sample two weeks after their last medication.<br /><br
        fxShow.xs
        fxHide.gt-xs
      />
      We can set the follow-up date and then send you an email reminder when it's time.<br />
    </p>
    <div
      fxLayout="column"
      fxLayout.gt-xs="row"
      fxLayoutAlign="start center"
      class="bottom-20"
      ngClass.gt-xs="top-20"
    >
      <p fxFlex tabindex="0">What day will they get their last dosage?</p>
      <mat-form-field appearance="outline" class="no-padding" ngClass.gt-xs="pad-left">
        <input
          matInput
          #lastDosageCtrl="ngModel"
          [matDatepicker]="lastDosageDatePicker"
          [(ngModel)]="lastDosageDate"
        />
        <mat-datepicker-toggle matSuffix [for]="lastDosageDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #lastDosageDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
  <div class="bottom-10">
    <button mat-button mat-dialog-close>No Thanks</button>
    <button
      mat-button
      color="primary"
      [disabled]="lastDosageCtrl.invalid || !lastDosageDate"
      (click)="close()"
    >
      OK
    </button>
  </div>
</mat-dialog-actions>
