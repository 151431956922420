<div fxLayout="row wrap" fxLayoutAlign="space-between" [class.top-20]="documents?.length">
  <div *ngIf="fosterId" fxFlex="100" fxFlex.gt-sm="100">
    <div fxShow.xs fxHide.gt-xs>
      <div *ngFor="let doc of unknownDocuments" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="name">{{ doc.name }}</div>
        <div class="button-row">
          <button
            *ngIf="!disabled"
            type="button"
            mat-icon-button
            color="primary"
            (click)="edit(doc)"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button type="button" mat-icon-button (click)="download.emit(doc.id)">
            <mat-icon>get_app</mat-icon>
          </button>
          <button
            *ngIf="!disabled"
            type="button"
            mat-icon-button
            color="warn"
            (click)="confirmDelete(doc)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div *ngFor="let group of groupedDocuments | keyvalue">
        <h4 class="top-20 bottom-5">{{ group.key | enumName: documentTypes }}s</h4>
        <div *ngFor="let doc of group.value" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="name">{{ doc.name }}</div>
          <div class="button-row">
            <button
              *ngIf="!disabled"
              type="button"
              mat-icon-button
              color="primary"
              (click)="edit(doc)"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button type="button" mat-icon-button (click)="download.emit(doc.id)">
              <mat-icon>get_app</mat-icon>
            </button>
            <button
              *ngIf="!disabled"
              type="button"
              mat-icon-button
              color="warn"
              (click)="confirmDelete(doc)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div fxHide.xs>
      <table
        *ngIf="documents?.length"
        mat-table
        matSort
        matSortActive="createdAt"
        matSortDisableClear
        matSortDirection="desc"
        [dataSource]="sortableDocuments"
      >
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.type | enumName: documentTypes }}
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">{{ element?.name }}</td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
          <td mat-cell *matCellDef="let element">{{ element?.createdAt | date }}</td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button
              *ngIf="!disabled"
              type="button"
              mat-icon-button
              color="primary"
              matTooltip="Edit"
              matTooltipPosition="above"
              (click)="edit(element)"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              type="button"
              mat-icon-button
              color="primary"
              matTooltip="Download"
              matTooltipPosition="above"
              (click)="download.emit(element.id)"
            >
              <mat-icon>get_app</mat-icon>
            </button>
            <button
              *ngIf="!disabled"
              type="button"
              mat-icon-button
              color="warn"
              matTooltip="Delete"
              matTooltipPosition="above"
              (click)="confirmDelete(element)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>
      </table>
    </div>
  </div>
</div>
<button
  *ngIf="fosterId && !disabled"
  type="button"
  mat-raised-button
  color="primary"
  style="margin-top: 20px"
  (click)="create()"
>
  Add
</button>
<div *ngIf="!fosterId" class="save-first">Save first, then upload documents.</div>
