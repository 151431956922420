import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RbSnackbar } from 'web/app/core/rb-snackbar';
import { User } from 'web/app/models/user.model';

import { AuthService } from './services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor, OnDestroy {
  private subscription;

  constructor(
    private authService: AuthService,
    private snackbar: RbSnackbar,
    private router: Router,
    private ngZone: NgZone
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // @ts-expect-error FIXME
            if (err.status === 401 && !err.url.includes('/api/user/me')) {
              console.log('Session expired', err);

              if (
                !this.router.url.includes('unauthorized') &&
                !this.router.url.includes('invite') &&
                !this.router.url.includes('auth/verify')
              ) {
                this.ngZone.run(() => {
                  this.router.navigate(['/login']).then();
                });
              }

              const cachedUser = User.getCached();
              if (!cachedUser.id) {
                // this.snackbar.info('Your session has expired. Please log in again.');
                this.authService.clearAppSession();
              }
            }
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
