import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { saveAs } from 'file-saver';
import { catchError, tap } from 'rxjs/operators';

import { FosterService } from 'web/app/core/services/foster.service';
import { Filter, FosterFilter } from 'web/app/models/foster-filter.model';
import { Foster } from 'web/app/models/foster.model';

import {
  ChangeAdvancedFiltersAction,
  ChangeFilterAction,
  ChangeSearchAction,
  DoBulkUpdateAction,
  ExportAllAction,
  LoadFostersAction,
  LoadMoreAction,
  ToggleSelectAction,
} from './foster.actions';

export interface FosterStateModel {
  fosters: Foster[];
  loadingFosters: boolean;
  total: number;
  filter: FosterFilter;
  search: string | null;
  advancedFilters?: Filter[];
  showUserName: boolean;
  selected: Foster[];
  processing: boolean;
}

@Injectable()
@State<FosterStateModel>({
  name: 'foster',
  defaults: {
    fosters: [],
    loadingFosters: false,
    total: 0,
    showUserName: false,
    advancedFilters: undefined,
    filter: {
      pageNumber: 1,
      pageSize: 25,
      sort: 'intakeDate',
      sortDesc: true,
      viewAll: false,
      includeDeceased: false,
      adoptableOnly: false,
      currentlyFostered: false,
      species: [],
    },
    search: null,
    selected: [],
    processing: false,
  },
})
export class FosterState {
  @Selector()
  static getState(state: FosterStateModel): FosterStateModel {
    return state;
  }

  @Selector()
  static fosters(state: FosterStateModel): Foster[] {
    return state.fosters;
  }

  @Selector()
  static loadingFosters(state: FosterStateModel) {
    return state.loadingFosters;
  }

  @Selector()
  static total(state: FosterStateModel) {
    return state.total;
  }

  @Selector()
  static filter(state: FosterStateModel) {
    return state.filter;
  }

  @Selector()
  static advancedFilters(state: FosterStateModel) {
    return state.advancedFilters;
  }

  @Selector()
  static search(state: FosterStateModel) {
    return state.search;
  }

  @Selector()
  static showUserName(state: FosterStateModel) {
    return state.showUserName;
  }

  @Selector()
  static selected(state: FosterStateModel) {
    return state.selected;
  }

  @Selector()
  static processing(state: FosterStateModel) {
    return state.processing;
  }

  constructor(private fosterService: FosterService) {}

  @Action(LoadFostersAction, { cancelUncompleted: true })
  loadFosters(ctx: StateContext<FosterStateModel>) {
    const state = ctx.getState();
    ctx.patchState({
      loadingFosters: true,
      selected: [],
    });
    if (!!state.advancedFilters && state.advancedFilters.length > 0) {
      return this.fosterService
        .getAdvancedPage(
          state.advancedFilters,
          state.filter.pageNumber.toString(),
          state.filter.pageSize.toString(),
          // @ts-expect-error FIXME
          state.search
        )
        .pipe(
          tap((data) => {
            ctx.patchState({
              fosters: state.filter.pageNumber > 1 ? [...state.fosters, ...data.items] : data.items,
              total: data.total,
              loadingFosters: false,
            });
          }),
          catchError((error) => {
            throw error;
          })
        );
    }
    // @ts-expect-error FIXME
    return this.fosterService.getSimplePage(state.search, state.filter).pipe(
      tap((data) => {
        ctx.patchState({
          fosters: state.filter.pageNumber > 1 ? [...state.fosters, ...data.items] : data.items,
          total: data.total,
          loadingFosters: false,
        });
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  @Action(ChangeSearchAction, { cancelUncompleted: true })
  changeSearch(ctx: StateContext<FosterStateModel>, { search }: ChangeSearchAction) {
    const state = ctx.getState();
    ctx.patchState({ search, filter: { ...state.filter, pageNumber: 1 } });
    ctx.dispatch(new LoadFostersAction());
  }

  @Action(ChangeFilterAction, { cancelUncompleted: true })
  changeFilter(ctx: StateContext<FosterStateModel>, { filter }: ChangeFilterAction) {
    const state = ctx.getState();
    ctx.patchState({
      filter: { ...state.filter, ...filter, pageNumber: 1 },
      showUserName: filter.viewAll,
    });
    ctx.dispatch(new LoadFostersAction());
  }

  @Action(ChangeAdvancedFiltersAction, { cancelUncompleted: true })
  changeAdvancedFilters(
    ctx: StateContext<FosterStateModel>,
    { filter }: ChangeAdvancedFiltersAction
  ) {
    const state = ctx.getState();
    if (!filter) {
      // @ts-expect-error FIXME
      ctx.patchState({ advancedFilters: null, showUserName: state.filter.viewAll });
    } else {
      ctx.patchState({
        advancedFilters: [...filter],
        filter: { ...state.filter, pageNumber: 1 },
        showUserName: true,
      });
    }
    ctx.dispatch(new LoadFostersAction());
  }

  @Action(LoadMoreAction, { cancelUncompleted: true })
  loadMore(ctx: StateContext<FosterStateModel>) {
    const state = ctx.getState();
    ctx.patchState({ filter: { ...state.filter, pageNumber: state.filter.pageNumber + 1 } });
    ctx.dispatch(new LoadFostersAction());
  }

  @Action(ExportAllAction, { cancelUncompleted: true })
  exportAll() {
    return this.fosterService.getCsv().pipe(
      tap((data) => saveAs(data.file, data.filename || 'all-fosters.csv')),
      catchError((error) => {
        throw error;
      })
    );
  }

  @Action(ToggleSelectAction)
  select(ctx: StateContext<FosterStateModel>, { foster }: ToggleSelectAction) {
    const state = ctx.getState();
    if (state.selected.some((x) => x.id === foster.id)) {
      ctx.patchState({ selected: state.selected.filter((x) => x.id !== foster.id) });
    } else {
      ctx.patchState({ selected: [...state.selected, foster] });
    }
  }

  @Action(DoBulkUpdateAction, { cancelUncompleted: true })
  doBulkUpdate(ctx: StateContext<FosterStateModel>, { updates }: DoBulkUpdateAction) {
    ctx.patchState({ processing: true });
    return this.fosterService.doBulkUpdate(updates).pipe(
      // tap(() => this.router.navigate(['foster/list'])),
      tap(() => ctx.patchState({ processing: false })),
      catchError((error) => {
        ctx.patchState({ processing: false });
        throw error;
      })
    );
  }
}
