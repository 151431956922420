import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/auth-guard';
import { LoginComponent } from './auth/login/login.component';
import { UnauthorizedComponent } from './auth/unauthorized/unauthorized.component';
import { VerifyComponent } from './auth/verify/verify.component';
import { PrivacyPolicyComponent } from './core/privacy-policy/privacy-policy.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'auth/verify',
    component: VerifyComponent,
  },
  {
    path: 'invite/:code',
    component: LoginComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: '',
    redirectTo: 'foster/list',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'foster',
    loadChildren: () => import('./foster/foster.module').then((m) => m.FosterModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'whats-next',
    loadChildren: () => import('./whats-next/whats-next.module').then((m) => m.WhatsNextModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'send-feedback',
    loadChildren: () =>
      import('./send-feedback/send-feedback.module').then((m) => m.SendFeedbackModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'user',
    data: { adminOnly: true },
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'organization',
    data: { adminOnly: true },
    loadChildren: () =>
      import('./organization/organization.module').then((m) => m.OrganizationModule),
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: 'foster/list' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
