import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE, MatRippleModule } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { enUS } from 'date-fns/locale';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import SuperTokens from 'supertokens-web-js';
import Passwordless from 'supertokens-web-js/recipe/passwordless';
import Session from 'supertokens-web-js/recipe/session';

import { environment } from 'web/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { DEVTOOLS_REDUX_CONFIG, OPTIONS_CONFIG } from './core/store.config';
import { FosterModule } from './foster/foster.module';
import { SavePageComponent } from './save-page/save-page.component';
import { SharedModule } from './shared/shared.module';

SuperTokens.init({
  appInfo: {
    apiDomain:
      window.location.origin.includes('localhost') ||
      window.location.origin.includes('rescuebase.io')
        ? window.location.origin
        : '',
    apiBasePath: '/api/auth',
    appName: 'rescuebase',
  },
  recipeList: [Session.init(), Passwordless.init()],
});

@NgModule({
  declarations: [AppComponent, SavePageComponent],
  imports: [
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsTag),
    NgxGoogleAnalyticsRouterModule,

    SharedModule,
    NgxsModule.forRoot([], OPTIONS_CONFIG),
    NgxsReduxDevtoolsPluginModule.forRoot(DEVTOOLS_REDUX_CONFIG),
    AuthModule.forRoot(),
    CoreModule.forRoot(),
    AppRoutingModule,
    FosterModule,
    MatRippleModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: enUS,
    },
  ],
})
export class AppModule {}
