import { Species } from './foster.model';

export class FosterFilter {
  viewAll: boolean;
  pageNumber: number;
  pageSize: number;
  sort: string;
  sortDesc: boolean;
  includeDeceased: boolean;
  adoptableOnly: boolean;
  currentlyFostered: boolean;
  species: Species[];
}

export enum FilterType {
  Date = 0,
  String = 1,
  Boolean = 2,
  Enum = 3,
}
export interface Filter {
  field: string;
  operator: FilterOperator;
  operands: boolean | number | string[];
}

export enum FilterOperator {
  GreaterThan = '>>',
  LessThan = '<<',
  Equals = '!!',
  NotEquals = '!',
  Contains = '~',
  DoesNotContain = '!~',
  // IsEmpty = 'empty',
  // NotEmpty = 'not_empty'
}
