import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import moment from 'moment';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { OrganizationService } from 'web/app/core/services/organization.service';
import { Species } from 'web/app/models/foster.model';
import { MedicalInfo, MedicalInfoType, Organization } from 'web/app/models/organization.model';
import { Vaccine } from 'web/app/models/vaccine.model';

import {
  DeleteMedicalInfoAction,
  DeleteVaccineAction,
  GetOrganizationAction,
  SaveMedicalInfoAction,
  SaveOrganizationAction,
  SaveVaccineAction,
} from './organization.actions';

export interface OrganizationStateModel {
  organization: Organization;
  loading: boolean;
  processing: boolean;
}

@Injectable()
@State<OrganizationStateModel>({
  name: 'organization',
  defaults: {
    // @ts-expect-error FIXME
    organization: null,
    loading: true,
    processing: false,
  },
})
export class OrganizationState {
  @Selector()
  static loading(state: OrganizationStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static processing(state: OrganizationStateModel): boolean {
    return state.processing;
  }

  @Selector()
  static organization(state: OrganizationStateModel): Organization {
    return state.organization;
  }

  @Selector()
  static vaccines(state: OrganizationStateModel): Vaccine[] {
    return state.organization?.vaccines || [];
  }

  @Selector()
  static vaccinesForSpecies(state: OrganizationStateModel) {
    return (species: Species): Vaccine[] =>
      state.organization?.vaccines?.filter((x) => x.species === species) || [];
  }

  @Selector()
  static medicalInfos(state: OrganizationStateModel): MedicalInfo[] {
    return state.organization?.medicalInfos || [];
  }

  private static filterMedicalInfos(
    state: OrganizationStateModel,
    type: MedicalInfoType,
    species: Species,
    birthDate: Date
  ): string {
    const ageInWeeks = moment().diff(birthDate, 'weeks');
    // @ts-expect-error FIXME
    if (!state.organization?.medicalInfos) return null;
    // @ts-expect-error FIXME
    return state.organization.medicalInfos
      .filter((x) => x.type === type)
      .filter((x) => x.species === undefined || x.species === species)
      .filter((x) => !x.minAge || ageInWeeks > x.minAge)
      .sort((x) => x.minAge)
      .pop()?.text;
  }

  @Selector()
  static vaccinationInfo(state: OrganizationStateModel) {
    return (species: Species, birthDate: Date): string =>
      this.filterMedicalInfos(state, MedicalInfoType.Vaccination, species, birthDate);
  }

  @Selector()
  static spayNeuterInfo(state: OrganizationStateModel) {
    return (species: Species, birthDate: Date): string =>
      this.filterMedicalInfos(state, MedicalInfoType.SpayAndNeuter, species, birthDate);
  }

  @Selector()
  static preventionInfo(state: OrganizationStateModel) {
    return (species: Species, birthDate: Date): string =>
      this.filterMedicalInfos(state, MedicalInfoType.Prevention, species, birthDate);
  }

  @Selector()
  static fecalInfo(state: OrganizationStateModel) {
    return (species: Species, birthDate: Date): string =>
      this.filterMedicalInfos(state, MedicalInfoType.Fecal, species, birthDate);
  }

  constructor(private organizationService: OrganizationService) {}

  @Action(GetOrganizationAction, { cancelUncompleted: true })
  getOrganization(ctx: StateContext<OrganizationStateModel>): Observable<Organization> {
    ctx.patchState({ loading: true });
    return this.organizationService.getCurrentOrganization().pipe(
      tap((result: Organization) => {
        ctx.patchState({ organization: result, loading: false });
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  @Action(SaveOrganizationAction, { cancelUncompleted: true })
  saveOrganization(
    ctx: StateContext<OrganizationStateModel>,
    { organization }: SaveOrganizationAction
  ): Observable<Organization> {
    const state = ctx.getState();
    ctx.patchState({ processing: true });
    return this.organizationService.save({ ...state.organization, ...organization }).pipe(
      tap((result: Organization) => {
        ctx.patchState({ organization: result, processing: false });
      })
    );
  }

  @Action(SaveVaccineAction, { cancelUncompleted: true })
  saveVaccine(
    ctx: StateContext<OrganizationStateModel>,
    { vaccine }: SaveVaccineAction
  ): Observable<Organization> {
    ctx.patchState({ processing: true });
    const state = ctx.getState();
    const vaccines = [...state.organization.vaccines];
    const index = vaccines.findIndex((x) => x.id === vaccine.id);
    if (index >= 0) {
      vaccines[index] = vaccine;
    } else {
      vaccines.push(vaccine);
    }
    return this.organizationService.save({ ...state.organization, vaccines }).pipe(
      tap((result: Organization) => {
        ctx.patchState({ organization: result, processing: false });
      })
    );
  }

  @Action(DeleteVaccineAction, { cancelUncompleted: true })
  deleteVaccine(
    ctx: StateContext<OrganizationStateModel>,
    { id }: DeleteVaccineAction
  ): Observable<Organization> {
    ctx.patchState({ processing: true });
    const state = ctx.getState();
    return this.organizationService
      .save({
        ...state.organization,
        vaccines: state.organization.vaccines.filter((x) => x.id !== id),
      })
      .pipe(
        tap((result: Organization) => {
          ctx.patchState({ organization: result, processing: false });
        })
      );
  }

  @Action(SaveMedicalInfoAction, { cancelUncompleted: true })
  saveMedicalInfo(
    ctx: StateContext<OrganizationStateModel>,
    { medicalInfo }: SaveMedicalInfoAction
  ): Observable<Organization> {
    ctx.patchState({ processing: true });
    const state = ctx.getState();
    const medicalInfos = [...state.organization.medicalInfos];
    const index = medicalInfos.findIndex((x) => x.id === medicalInfo.id);
    if (index >= 0) {
      medicalInfos[index] = medicalInfo;
    } else {
      medicalInfos.push(medicalInfo);
    }
    return this.organizationService.save({ ...state.organization, medicalInfos }).pipe(
      tap((result: Organization) => {
        ctx.patchState({ organization: result, processing: false });
      })
    );
  }

  @Action(DeleteMedicalInfoAction, { cancelUncompleted: true })
  deleteMedicalInfo(
    ctx: StateContext<OrganizationStateModel>,
    { id }: DeleteMedicalInfoAction
  ): Observable<Organization> {
    ctx.patchState({ processing: true });
    const state = ctx.getState();
    return this.organizationService
      .save({
        ...state.organization,
        medicalInfos: state.organization.medicalInfos.filter((x) => x.id !== id),
      })
      .pipe(
        tap((result: Organization) => {
          ctx.patchState({ organization: result, processing: false });
        })
      );
  }
}
