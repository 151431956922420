import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as Sentry from '@sentry/browser';
import * as LogRocket from 'logrocket';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Observable, from, timer } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import Session from 'supertokens-web-js/recipe/session';

import { AuthService } from 'web/app/auth/services/auth.service';
import { GetOrganizationAction } from 'web/app/core/organization-store/organization.actions';
import { User } from 'web/app/models/user.model';
import { environment } from 'web/environments/environment';

import {
  CheckLoginStatusAction,
  LoggingIn,
  LoginSuccessAction,
  LogoutAction,
  PollLoginStatusAction,
} from './auth.actions';

export interface AuthStateModel {
  user: User;
  loggingIn: boolean;
}

@Injectable()
@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    // @ts-expect-error FIXME
    user: null,
    loggingIn: true,
  },
})
export class AuthState {
  @Selector()
  static getAuthData(state: AuthStateModel): AuthStateModel {
    return AuthState.getInstanceState(state);
  }

  @Selector()
  static user(state: AuthStateModel): User {
    return state.user;
  }

  @Selector()
  static loggedIn(state: AuthStateModel): boolean {
    return !!state.user;
  }

  @Selector()
  static loggingIn(state: AuthStateModel): boolean {
    return state.loggingIn;
  }

  @Selector()
  static isAdmin(state: AuthStateModel): boolean {
    return state.user.isAdmin;
  }

  @Selector()
  static isAdoptionCoordinator(state: AuthStateModel): boolean {
    return state.user.isAdoptionCoordinator;
  }

  private static getInstanceState(state: AuthStateModel): AuthStateModel {
    return { ...state };
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private ngZone: NgZone,
    private gaService: GoogleAnalyticsService
  ) {}

  @Action(CheckLoginStatusAction, { cancelUncompleted: true })
  checkLoginStatus(ctx: StateContext<AuthStateModel>): Observable<User> {
    ctx.patchState({ user: User.getCached() });

    return this.authService.checkLoginStatus().pipe(
      tap((result: User) => {
        const user = new User(result);
        ctx.patchState({ user });
        user.cache();

        ctx.dispatch(new LoginSuccessAction());
      }),
      catchError(async (error) => {
        console.log('check login status error', error);

        const refreshSession = await Session.attemptRefreshingSession();

        if (refreshSession) {
          ctx.dispatch(new CheckLoginStatusAction());
        } else {
          // @ts-expect-error FIXME
          ctx.patchState({ user: null, loggingIn: false });

          this.ngZone.run(() => {
            this.router.navigate(['/login']).then();

            if (error.status === 401) {
              localStorage.removeItem('currentUser');
            }
          });
        }

        // eslint-disable-next-line no-throw-literal
        throw { isHandled: true };
      })
    );
  }

  @Action(LoginSuccessAction, { cancelUncompleted: true })
  loginSuccess(ctx: StateContext<AuthStateModel>): void {
    ctx.patchState({ loggingIn: false });
    const state = ctx.getState();
    if (state.user && environment.production) {
      Sentry.setUser({ id: state.user.id });
      datadogRum.setUser({
        id: state.user.id,
        name: `${state.user.firstName} ${state.user.lastName}`,
        email: state.user.email,
      });
    }
    this.gaService.gtag('set', 'user_id', [state.user.id]);
    LogRocket.identify(state.user.id);

    ctx.dispatch(new GetOrganizationAction());

    if (this.router.url === '/login') {
      this.ngZone.run(() => {
        this.router.navigate(['/foster/list']).then();
      });
    }
  }

  @Action(LoggingIn, { cancelUncompleted: true })
  loggingIn(ctx: StateContext<AuthStateModel>): void {
    ctx.patchState({ loggingIn: true });
  }

  @Action(PollLoginStatusAction, { cancelUncompleted: true })
  pollLoginStatus(ctx: StateContext<AuthStateModel>): void {
    const statusCheckTimer = timer(0, 10 * 60 * 1000);
    statusCheckTimer.subscribe(() => {
      ctx.dispatch(new CheckLoginStatusAction());
    });
  }

  @Action(LogoutAction, { cancelUncompleted: true })
  logout({ getState, patchState }: StateContext<AuthStateModel>): Observable<void> {
    const state = getState();
    if (!state.user) {
      // @ts-expect-error FIXME
      return;
    }

    return from(Session.signOut()).pipe(
      tap(() => {
        // @ts-expect-error FIXME
        patchState({ user: null });
        this.authService.clearAppSession();
        this.ngZone.run(() => {
          this.router.navigate(['/login']).then();
        });
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
}
