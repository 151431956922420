<form>
  <div *ngFor="let filter of advancedFiltersForm.controls; let i = index">
    <div fxLayout="row wrap" fxLayoutAlign="start" class="filter-wrapper" [formGroup]="filter">
      <mat-form-field class="filter-select filter-field">
        <mat-label>Field</mat-label>
        <mat-select formControlName="field">
          <mat-option *ngFor="let schema of filterSchema" [value]="schema">{{
            schema.title
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Default -->
      <mat-form-field class="filter-select">
        <mat-label>Operator</mat-label>
        <mat-select formControlName="operator">
          <mat-option
            *ngFor="let operator of filter.get('field').value?.operators"
            [value]="operator.value"
          >
            {{ operator.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- String Operands -->
      <mat-form-field class="filter-operands string-operand" *ngIf="shouldShowStringField(filter)">
        <mat-label>Filters</mat-label>
        <mat-chip-list #chipList aria-label="">
          <mat-chip
            *ngFor="let operand of filter.get('operands').value"
            [selectable]="false"
            [removable]="true"
            (removed)="removeOperandChip(i, operand)"
          >
            {{ operand }}
            <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
          </mat-chip>
          <input
            [matChipInputFor]="chipList"
            matChipInputAddOnBlur
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addOperandChip(i, $event)"
          />
        </mat-chip-list>
        <mat-hint>Hint: Add multiple search terms by pressing 'Enter' between terms.</mat-hint>
      </mat-form-field>

      <!-- Enum Operands -->
      <mat-form-field *ngIf="shouldShowEnumField(filter)" class="filter-select">
        <mat-label>Value</mat-label>
        <mat-select formControlName="operands">
          <mat-option
            *ngFor="let e of filter.get('field').value?.enumType | enumToList"
            [value]="e.id"
          >
            {{ e.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Date Operands -->
      <mat-form-field class="filter-operands" *ngIf="shouldShowDateField(filter)">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="operands" autocomplete="off" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <button mat-icon-button class="remove-filter" (click)="removeFilter(i)">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
  <button mat-raised-button [disabled]="advancedFiltersForm.invalid" (click)="addFilter()">
    Add Filter
  </button>
</form>
