import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { RbSnackbar } from 'web/app/core/rb-snackbar';
import { Organization } from 'web/app/models/organization.model';
import { environment } from 'web/environments/environment';

import { BaseService } from './base.service';

@Injectable()
export class OrganizationService extends BaseService {
  private adoptionContractName = 'Adoption Contract.pdf';

  constructor(
    protected http: HttpClient,
    protected snackbar: RbSnackbar,
    protected logger: NGXLogger
  ) {
    super(http, snackbar, logger);
    this.baseUri = environment.apiUri + '/organization';
  }

  getCurrentOrganization(): Observable<Organization> {
    return this.http.get(`${this.baseUri}/current`).pipe(
      map((response: Organization) => response),
      catchError((error) => this.handleError(error, 'Error getting organization info'))
    );
  }

  getAdoptionContractUrl(): string {
    return `${this.baseUri}/files/${this.adoptionContractName}`;
  }

  getAdoptionContract(): Observable<Blob> {
    return this.http.get(`${this.baseUri}/files/${this.adoptionContractName}`, {
      responseType: 'blob',
    });
  }

  save(organization: Organization): Observable<Organization> {
    this.snackbar.wait('Saving...');
    return this.http.put(this.baseUri, organization).pipe(
      map((response: Organization) => response),
      tap(() => this.snackbar.success('Save successful!', 1000)),
      catchError((error) => this.handleError(error, 'Error saving organization info'))
    );
  }
}
