import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { NGXLogger } from 'ngx-logger';

import { RbSnackbar } from 'web/app/core/rb-snackbar';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent {
  @Input() fosterId: string;
  @Input() disabled: boolean;

  @Output() upload = new EventEmitter<any>();

  @ViewChild('file') file;
  private validFileTypes = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'svg', 'pdf', 'doc', 'docx'];
  private maxFileSize = 20000000; // 20Mb

  constructor(private snackbar: RbSnackbar, private logger: NGXLogger) {}

  selectFile() {
    this.file.nativeElement.click();
    this.logger.info('[FILE_UPLOAD] selectFile');
  }

  onFileSelected() {
    this.logger.info('[FILE_UPLOAD] onFileSelected');
    const files: { [key: string]: File } = this.file.nativeElement.files;
    this.logger.info('[FILE_UPLOAD] onFileSelected ' + JSON.stringify(files, null, 2));

    const fileKeys = Object.keys(files);
    for (const key in fileKeys) {
      if (!isNaN(Number(key))) {
        // Currently we only support a single file upload. Not all file explorers/operating systems support
        // omiting `multiple` from the `input` tag and therefore we need our own logic as well 🙄
        if (fileKeys.findIndex((k) => k === key) > 0) {
          this.snackbar.wait(
            `Only one file can be uploaded at a time. Currently uploading [${files[0].name}]. Please upload files individually.`
          );
          return;
        }

        if (!this.isValidFileType(files[key].name)) {
          return;
        }
        if (this.isOverMaxFileSize(files[key].size)) {
          return;
        }
        this.logger.info('[FILE_UPLOAD] about to emit upload event');
        this.upload.emit({ file: files[key], filePath: files[key].name });
        this.logger.info('[FILE_UPLOAD] upload event emitted');
      }
    }
  }

  dropped(files: NgxFileDropEntry[]) {
    this.logger.info('[FILE_UPLOAD] dropped');
    for (const droppedFile of files) {
      this.logger.info('[FILE_UPLOAD] dropped ' + JSON.stringify(files, null, 2));
      if (!droppedFile.fileEntry.isFile) {
        continue;
      }
      if (!this.isValidFileType(droppedFile.relativePath)) {
        return;
      }

      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        if (this.isOverMaxFileSize(file.size)) {
          return;
        }
        this.logger.info('[FILE_UPLOAD] about to emit upload event');
        this.upload.emit({ file, filePath: droppedFile.relativePath });
        this.logger.info('[FILE_UPLOAD] upload event emitted');
      });
    }
  }

  private isOverMaxFileSize(size: number) {
    if (size > this.maxFileSize) {
      this.logger.info('[FILE_UPLOAD] file over max size');
      this.snackbar.error('Please choose a file less than 20Mb');
      return true;
    }
    this.logger.info('[FILE_UPLOAD] file within max size');
    return false;
  }

  private isValidFileType(fileName: string) {
    if (!fileName) {
      this.logger.info('[FILE_UPLOAD] invalid file type because fileName is empty');
      this.snackbar.error('Please upload a valid file type : ' + this.validFileTypes.join(', '));
      return false;
    }

    this.logger.info('[FILE_UPLOAD] valid file type ' + fileName);

    const splitFileName = fileName.split('.');
    const fileType = splitFileName[splitFileName.length - 1].toLowerCase();
    if (this.validFileTypes.indexOf(fileType) === -1) {
      this.logger.info('[FILE_UPLOAD] invalid file type: ' + fileType);
      this.snackbar.error('Please upload a valid file type: ' + this.validFileTypes.join(', '));
      return false;
    }
    return true;
  }
}
