import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';

import { CalendarLinkDialogComponent } from './components/calendar-link-dialog/calendar-link-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { PawLoaderComponent } from './components/paw-loader/paw-loader.component';
import { SaveStatusComponent } from './components/save-status/save-status.component';
import { UserSelectComponent } from './components/user-select/user-select.component';
import { LongPressDirective } from './directives/long-press.directive';
import { ShortPressDirective } from './directives/short-press.directive';
import { MaterialModule } from './material.module';
import { EnumNamePipe } from './pipes/enum-name.pipe';
import { EnumToListPipe } from './pipes/enum-to-list.pipe';

@NgModule({
  imports: [
    ClipboardModule,
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    EnumNamePipe,
    EnumToListPipe,
    CalendarLinkDialogComponent,
    ConfirmDialogComponent,
    SaveStatusComponent,
    LongPressDirective,
    ShortPressDirective,
    PawLoaderComponent,
    LoadingSpinnerComponent,
    UserSelectComponent,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    EnumNamePipe,
    EnumToListPipe,
    LongPressDirective,
    ShortPressDirective,
    SaveStatusComponent,
    PawLoaderComponent,
    LoadingSpinnerComponent,
    UserSelectComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class SharedModule {}
