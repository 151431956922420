import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ClickOutsideModule } from 'ng-click-outside';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxFileDropModule } from 'ngx-file-drop';

import { SharedModule } from 'web/app/shared/shared.module';

import { AdoptionDialogComponent } from './adoptions/adoption-dialog/adoption-dialog.component';
import { AdoptionsComponent } from './adoptions/adoptions.component';
import { BulkUpdateComponent } from './bulk-update/bulk-update.component';
import { DocumentDialogComponent } from './documents/document-dialog/document-dialog.component';
import { FileUploadComponent } from './documents/document-dialog/file-upload/file-upload.component';
import { DocumentsComponent } from './documents/documents.component';
import { FecalFollowupDialogComponent } from './foster-detail/fecal-followup-dialog/fecal-followup-dialog.component';
import { FosterDetailComponent } from './foster-detail/foster-detail.component';
import { MissingInfoDialogComponent } from './foster-detail/missing-info-dialog/missing-info-dialog.component';
import { FosterListComponent } from './foster-list/foster-list.component';
import { FosterTileComponent } from './foster-list/foster-tile/foster-tile.component';
import { AdvancedFilterComponent } from './foster-list/header/advanced-filter/advanced-filter.component';
import { FosterListHeaderComponent } from './foster-list/header/header.component';
import { SimpleFilterComponent } from './foster-list/header/simple-filter/simple-filter.component';
import { FosterRoutingModule } from './foster-routing.module';
import { FosterState } from './foster-store/foster.state';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { BaseFosterInfoComponent } from './shared/base-foster-info/base-foster-info.component';
import { MedicalInfoComponent } from './shared/medical-info/medical-info.component';
import { VaccinationComponent } from './vaccinations/vaccination.component';
import { VaccinationsComponent } from './vaccinations/vaccinations.component';

@NgModule({
  declarations: [
    AdvancedFilterComponent,
    SimpleFilterComponent,
    FosterListComponent,
    FosterListHeaderComponent,
    FosterDetailComponent,
    ImageUploadComponent,
    FileUploadComponent,
    DocumentsComponent,
    DocumentDialogComponent,
    FecalFollowupDialogComponent,
    FosterTileComponent,
    MedicalInfoComponent,
    BulkUpdateComponent,
    BaseFosterInfoComponent,
    AdoptionsComponent,
    AdoptionDialogComponent,
    VaccinationsComponent,
    VaccinationComponent,
    MissingInfoDialogComponent,
  ],
  imports: [
    SharedModule,
    FosterRoutingModule,
    NgxFileDropModule,
    ClipboardModule,
    ClickOutsideModule,
    NgxsModule.forFeature([FosterState]),
  ],
})
export class FosterModule {}
