import { FosterVaccination, isEqual as isVaccinationEqual } from './foster-vaccination.model';
import { User } from './user.model';

export class Foster {
  alternateNames: string[];
  id: string;
  user: User;
  name: string;
  species: Species;
  gender: Gender;
  breed: string;
  birthDate: Date;
  heartwormTreatmentDate: Date;
  nextVaccinesDue: Date;
  nextHeartwormMedDue: Date;
  nextFleaTickMedDue: Date;
  positiveFecalTestDate: Date;
  clearFecalTestDate: Date;
  fecalFollowUpDate: Date;
  vetId: string;
  fixedOnDate: Date;
  fixedBeforeIntake: boolean;
  microchipId: string;
  fecalTreatment: string;
  readyForAdoption: boolean;
  history: string;
  notes: string;
  privateNotes: string;
  thumbUri: string;
  intakeDate: Date;
  currentFood: string;
  training: string;
  deceasedDate: Date;
  isAdopted: boolean;
  isInHospice: boolean;
  blurhash: string;
  heartwormTestingCompletedDate: Date;
  heartwormPositive: boolean;
  comboTestingCompletedDate: Date;
  felvPositive: boolean;
  fivPositive: boolean;
  vaccinations: FosterVaccination[];
  weight: number;
  weightDate: Date;

  updatedBy: string;
  updatedAt: Date;
}

export const isEqual = (f1: Foster, f2: Foster): boolean => {
  return (
    f1.user?.id === f2.user?.id &&
    f1.name === f2.name &&
    f1.species === f2.species &&
    f1.gender === f2.gender &&
    f1.breed === f2.breed &&
    f1.birthDate === f2.birthDate &&
    f1.heartwormTestingCompletedDate === f2.heartwormTestingCompletedDate &&
    f1.heartwormPositive === f2.heartwormPositive &&
    f1.comboTestingCompletedDate === f2.comboTestingCompletedDate &&
    f1.fivPositive === f2.fivPositive &&
    f1.felvPositive === f2.felvPositive &&
    f1.nextHeartwormMedDue === f2.nextHeartwormMedDue &&
    f1.nextFleaTickMedDue === f2.nextFleaTickMedDue &&
    f1.nextVaccinesDue === f2.nextVaccinesDue &&
    f1.fixedOnDate === f2.fixedOnDate &&
    f1.fixedBeforeIntake === f2.fixedBeforeIntake &&
    f1.microchipId === f2.microchipId &&
    f1.positiveFecalTestDate === f2.positiveFecalTestDate &&
    f1.clearFecalTestDate === f2.clearFecalTestDate &&
    f1.fecalFollowUpDate === f2.fecalFollowUpDate &&
    f1.readyForAdoption === f2.readyForAdoption &&
    f1.history === f2.history &&
    f1.notes === f2.notes &&
    f1.privateNotes === f2.privateNotes &&
    f1.intakeDate === f2.intakeDate &&
    f1.currentFood === f2.currentFood &&
    f1.training === f2.training &&
    f1.deceasedDate === f2.deceasedDate &&
    f1.isAdopted === f2.isAdopted &&
    f1.isInHospice === f2.isInHospice &&
    f1.weight === f2.weight &&
    f1.weightDate === f2.weightDate &&
    isVaccinationsEqual(f1.vaccinations, f2.vaccinations)
  );
};

function isVaccinationsEqual(list1: FosterVaccination[], list2: FosterVaccination[]): boolean {
  if (list1?.length !== list2?.length) return false;
  for (let i = 0; i < list1.length; i++) {
    if (!isVaccinationEqual(list1[i], list2[i])) {
      return false;
    }
  }
  return true;
}

export enum Species {
  Dog = 0,
  Cat = 1,
}

export enum Gender {
  Male = 0,
  Female = 1,
}
