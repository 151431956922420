<div [formGroup]="form">
  <div fxLayout="row wrap" fxLayoutAlign="space-between">
    <div *ngIf="name" fxFlex="60" fxFlex.gt-sm="40">
      <mat-form-field appearance="standard">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" readonly />
      </mat-form-field>
    </div>
    <div fxFlex="34" fxFlex.gt-sm="20">
      <mat-form-field appearance="standard">
        <mat-label>Species</mat-label>
        <mat-select formControlName="species" disabled>
          <mat-option *ngFor="let s of speciesType | enumToList" [value]="s.id">{{
            s.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-xs="60" fxFlex.gt-sm="30">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div
          [fxFlex]="
            currentUser?.isAdmin || currentUser?.isAdoptionCoordinator
              ? '0 0 calc(100% - 40px)'
              : '100%'
          "
        >
          <app-user-select [form]="form" [disabled]="true"></app-user-select>
        </div>
        <div
          fxFlex="30px"
          style="margin-left: 10px"
          *ngIf="currentUser?.isAdmin || currentUser?.isAdoptionCoordinator"
        >
          <button
            type="button"
            mat-icon-button
            matTooltip="Copy Email"
            ngxClipboard
            *ngxClipboardIfSupported
            [cbContent]="user.value?.email"
            (cbOnSuccess)="notifyCopied()"
          >
            <img width="65%" src="assets/copy_email.png" alt="Copy Email" />
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="form.get('gender')" fxFlex="47" fxFlex.gt-sm="26">
      <mat-form-field appearance="standard">
        <mat-label>Gender</mat-label>
        <mat-select formControlName="gender" disabled>
          <mat-option *ngFor="let g of gender | enumToList" [value]="g.id">{{ g.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="47" fxFlex.gt-sm="40">
      <mat-form-field appearance="standard">
        <mat-label>Breed</mat-label>
        <input matInput type="text" formControlName="breed" readonly />
      </mat-form-field>
    </div>

    <div fxFlex="47" fxFlex.gt-sm="26">
      <mat-form-field appearance="standard">
        <mat-label>Birth Date</mat-label>
        <input matInput [matDatepicker]="birthDate" formControlName="birthDate" readonly />
        <mat-datepicker-toggle matSuffix [for]="birthDate" disabled></mat-datepicker-toggle>
        <mat-datepicker #birthDate disabled></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxFlex="47" fxFlex.gt-md="26">
      <mat-form-field appearance="standard">
        <mat-label>Intake Date</mat-label>
        <input matInput [matDatepicker]="intakeDate" formControlName="intakeDate" readonly />
        <mat-datepicker-toggle matSuffix [for]="intakeDate" disabled></mat-datepicker-toggle>
        <mat-datepicker #intakeDate disabled></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxFlex="47" fxFlex.gt-md="40">
      <mat-form-field appearance="standard">
        <mat-label>Current Food</mat-label>
        <input matInput type="text" formControlName="currentFood" readonly />
      </mat-form-field>
    </div>
    <div fxFlex="47" fxFlex.gt-sm="40" fxFlex.gt-md="26">
      <mat-form-field appearance="standard">
        <mat-label>Weight</mat-label>
        <input matInput type="text" formControlName="weight" readonly />
        <span matSuffix>lb</span>
        <mat-hint *ngIf="showWeightDate">
          Last updated {{ weightDate | date: 'shortDate' }}
        </mat-hint>
      </mat-form-field>
    </div>
    <div *ngIf="form.get('isInHospice')" fxFlex="47" fxFlex.gt-md="26" fxLayoutAlign="start center">
      <mat-slide-toggle formControlName="isInHospice" class="bottom-5" disabled
        >Hospice</mat-slide-toggle
      >
    </div>
  </div>
</div>
