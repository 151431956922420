import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { consumeCode, clearLoginAttemptInfo } from 'supertokens-web-js/recipe/passwordless';

import { CheckLoginStatusAction, LoggingIn } from 'web/app/auth/store/auth.actions';
import { RbSnackbar } from 'web/app/core/rb-snackbar';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<boolean>();
  code: string;

  constructor(
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private snackbar: RbSnackbar,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new LoggingIn());

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(async (params) => {
      try {
        const response = await consumeCode();

        this.logger.info(response);
        if (response.status === 'OK') {
          this.store.dispatch(new CheckLoginStatusAction());

          // we clear the login attempt info that was added when the createCode function
          // was called since the login was successful.
          await clearLoginAttemptInfo();

          if (response.createdNewRecipeUser && response.user.loginMethods.length === 1) {
            // user sign up success
          } else {
            // user sign in success
          }
        } else {
          // this can happen if the magic link has expired or is invalid
          // or if it was denied due to security reasons in case of automatic account linking

          // we clear the login attempt info that was added when the createCode function
          // was called - so that if the user does a page reload, they will now see the
          // enter email / phone UI again.
          await clearLoginAttemptInfo();
          this.snackbar.error('Login failed. Please try again');
        }
      } catch (err: any) {
        if (err.isSuperTokensGeneralError === true) {
          // this may be a custom error message sent from the API by you.
          this.logger.error(err);
        } else {
          this.snackbar.error('Oops! Something went wrong!');
          this.logger.error(err);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
