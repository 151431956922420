import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class PrintService {
  private readonly isInStandaloneMode: boolean;

  constructor(private deviceService: DeviceDetectorService) {
    // Detect if device is in standalone mode
    // @ts-expect-error FIXME
    this.isInStandaloneMode = 'standalone' in window.navigator && window.navigator.standalone;
  }

  canOpenDocumentInNewTab() {
    // In a PWA on an iOS device just download the pdf rather than open it in a new tab,
    // because a new tab in a PWA has no menu options for download or print
    return !(
      this.deviceService.browser.toLowerCase() === 'safari' &&
      (this.deviceService.isMobile || this.deviceService.isTablet) &&
      this.isInStandaloneMode
    );
  }

  printPdf(pdf: Blob, filename: string) {
    return new Promise<void>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onloadend = (): void => {
        const a = document.createElement('a');
        a.href = fileReader.result as string;
        a.style.display = 'none';
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        // @ts-expect-error FIXME
        a.parentNode.removeChild(a);
        resolve();
      };
      fileReader.onerror = (e): void => {
        return reject(e);
      };
      const blob = new Blob([pdf], { type: 'applications/pdf' });
      fileReader.readAsDataURL(blob);
    });
  }
}
