import { Filter, FosterFilter } from 'web/app/models/foster-filter.model';
import { Foster } from 'web/app/models/foster.model';

export class LoadFostersAction {
  static readonly type = '[Foster] Load Fosters';
}

export class LoadMoreAction {
  static readonly type = '[Foster] Load More';
}

export class ChangeSearchAction {
  static readonly type = '[Foster] Change Search';
  constructor(public search: string) {}
}

export class ChangeFilterAction {
  static readonly type = '[Foster] Change Filter';
  constructor(public filter: FosterFilter) {}
}

export class ChangeAdvancedFiltersAction {
  static readonly type = '[Foster] Change Advanced Filters';
  constructor(public filter: Filter[]) {}
}

export class ExportAllAction {
  static readonly type = '[Foster] Export All';
}

export class ToggleSelectAction {
  static readonly type = '[Foster] Toggle Select';
  constructor(public foster: Foster) {}
}

export class DoBulkUpdateAction {
  static readonly type = '[Foster] Do Bulk Update';
  constructor(public updates: Foster) {}
}
