<mat-dialog-content [class.no-padding]="zoomPreview">
  <div *ngIf="!zoomPreview && !editMode" class="close-container">
    <button mat-icon-button mat-dialog-close class="close-button" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngIf="step === documentUploadSteps.Upload">
    <h1 tabindex="0">Upload a Document</h1>
    <p *ngIf="!previewLoading" class="info-text">
      <small>
        Add an adoption contract, owner surrender form, etc.<br />
        If you upload a photo, we'll do our best to turn it into a printable file.
      </small>
    </p>
    <app-file-upload
      *ngIf="!thumbnail && !previewLoading && !editMode"
      (upload)="upload($event)"
    ></app-file-upload>
    <div *ngIf="previewLoading">
      <div *ngIf="isFileScannable" fxLayout="column" fxLayoutAlign="start center">
        <p class="text-center"><small>Give us a minute to upload and scan.</small></p>
        <mat-spinner
          mode="determinate"
          [value]="spinnerProgress"
          class="top-30"
          diameter="80"
        ></mat-spinner>
        <p class="top-40 text-center">{{ scanningMessage }}</p>
      </div>
      <div *ngIf="!isFileScannable" fxLayout="column" fxLayoutAlign="start center">
        <mat-spinner class="top-50" diameter="80"></mat-spinner>
        <p class="top-40 text-center">Uploading...</p>
      </div>
    </div>
  </div>
  <div *ngIf="step === documentUploadSteps.Review" class="review">
    <h1 *ngIf="!zoomPreview">How did we do?</h1>
    <div fxLayout="column" fxLayout.gt-xs="row">
      <div *ngIf="thumbnail" class="preview" [class.zoom]="zoomPreview" (click)="toggleZoomView()">
        <img [src]="thumbnail" alt="Scan Preview" />
      </div>
      <div *ngIf="!zoomPreview" fxLayout="column">
        <p class="text-center" ngClass.gt-sm="bottom-50" (click)="toggleZoomView()">
          <small fxLayoutAlign="center center">
            <mat-icon fxHide fxShow.gt-xs="true">arrow_back</mat-icon>
            <span fxHide fxShow.gt-sm>Click&nbsp;</span
            ><span fxShow fxHide.gt-sm>Tap&nbsp;</span> to zoom</small
          >
        </p>

        <button mat-button color="primary" (click)="accept()">Accept Scan</button>
        <button mat-button color="accent" (click)="keepOriginal()">Keep Original</button>
        <button mat-button (click)="tryAgain()">Try Again</button>
      </div>
    </div>
  </div>

  <div *ngIf="step === documentUploadSteps.Info">
    <h1>Document Info</h1>
    <form [formGroup]="form" style="height: 100%">
      <div fxFlex fxLayout="column" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <div fxFlex>
            <mat-form-field appearance="standard">
              <mat-label>Name</mat-label>
              <input matInput type="text" formControlName="name" required />
              <div matSuffix>{{ extension }}</div>
              <mat-error *ngIf="name.errors?.required">This field is required</mat-error>
              <mat-error *ngIf="name.errors?.uniqueName"
                >A file with this name already exists</mat-error
              >
            </mat-form-field>
          </div>
          <div fxFlex>
            <mat-form-field appearance="standard">
              <mat-label>Document Type</mat-label>
              <mat-select formControlName="type">
                <mat-option *ngFor="let t of documentTypes | enumToList" [value]="t.id">{{
                  t.name
                }}</mat-option>
              </mat-select>
              <mat-error *ngIf="type.invalid">This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div [fxLayoutAlign]="editMode ? 'end' : 'space-between'" class="top-20">
          <button *ngIf="editMode" mat-button mat-dialog-close>Cancel</button>
          <button *ngIf="!editMode || !isFileScannable" mat-button (click)="back()">Back</button>
          <button
            mat-button
            tabindex="-1"
            color="primary"
            [disabled]="form.invalid || form.pristine"
            (click)="save()"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
