<mat-dialog-content>
  {{ data.text }}
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end" class="top-20">
  <button mat-button tabindex="-1" mat-dialog-close>Cancel</button>
  <button
    mat-button
    tabindex="-1"
    [color]="data.confirmColor ? data.confirmColor : 'primary'"
    [mat-dialog-close]="true"
  >
    {{ data.confirmText ? data.confirmText : 'OK' }}
  </button>
</mat-dialog-actions>
