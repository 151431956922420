import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { Filter, FosterFilter } from 'web/app/models/foster-filter.model';

@Component({
  selector: 'app-foster-list-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FosterListHeaderComponent implements OnChanges, OnDestroy {
  @Input() filter: FosterFilter | null;
  @Input() advancedFilters: Filter[];
  @Input() search: string;
  @Input() isAdmin: boolean;
  @Input() total: number;
  @Input() loading: boolean;
  @Output() changeSearch = new EventEmitter<string>();
  @Output() changeFilter = new EventEmitter<FosterFilter>();
  @Output() changeAdvancedFilters = new EventEmitter<Filter[]>();
  @Output() exportAll = new EventEmitter();
  @Output() changeShowFilters = new EventEmitter<boolean>();

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  private destroy$ = new Subject<boolean>();

  searchControl = new FormControl();
  showFilters = false;
  filterForm: FormGroup = new FormGroup({});
  advancedFilter = false;

  constructor(private fb: FormBuilder) {
    this.searchControl.valueChanges
      .pipe(debounceTime(150), takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value !== this.search) {
          this.changeSearch.emit(value);
        }
      });
  }

  advancedFilterChange(event): void {
    this.changeAdvancedFilters.emit(event);
  }

  changeSearchEvent(event): void {
    this.changeSearch.emit(event);
  }

  changeSimpleFilter(event): void {
    this.changeFilter.emit(event);
  }

  toggleAdvancedFilters(): void {
    this.advancedFilter = !this.advancedFilter;
    if (this.advancedFilter) {
      this.filterForm.get('viewAll')?.disable();
    } else {
      this.changeAdvancedFilters.emit();
      this.filterForm.get('viewAll')?.enable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter) {
      this.filterForm = this.fb.group({
        viewAll: this.filter?.viewAll,
        adoptableOnly: this.filter?.adoptableOnly,
        currentlyFostered: this.filter?.currentlyFostered,
        includeDeceased: this.filter?.includeDeceased,
        species: [this.filter?.species],
        advancedFilter: true,
      });
      this.filterForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((form) => {
        this.changeFilter.emit({ ...form });
      });
    }
    if (changes.search) {
      this.searchControl.patchValue(this.search);
    }
    if (changes.advancedFilters?.currentValue?.length) {
      this.advancedFilter = true;
    }
  }

  toggleShowFilters(): void {
    this.showFilters = !this.showFilters;
    this.changeShowFilters.emit(this.showFilters);
  }

  closeFilters(event): void {
    if (!this.showFilters) {
      return;
    }
    if (
      !event?.path ||
      !event.path.some(
        (x: HTMLElement) =>
          x?.classList &&
          (x.classList.contains('filters') || x.classList.contains('mat-datepicker-popup'))
      )
    ) {
      this.showFilters = false;
      this.changeShowFilters.emit(false);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
