<mat-dialog-content fxLayout="column">
  <h1>STOP</h1>
  <div>
    <p *ngIf="data.comboTest">
      All cats should be combo tested before being adopted.
      <br />
      Please add the combo test date or schedule a vet appointment before proceeding.
    </p>
    <p *ngIf="data.heartwormTest">
      All dogs must be heartworm tested before being adopted.
      <br />
      Please add the heartworm test date or schedule a vet appointment before proceeding.
    </p>
    <p *ngIf="data.vaccinations">
      All fosters must be vaccinated before being adopted.
      <br />
      Please add the vaccinations or schedule a vet appointment before proceeding.
    </p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" mat-dialog-close>Go Back</button>
  <button mat-button color="error" (click)="close(true)">Continue Anyway</button>
</mat-dialog-actions>
