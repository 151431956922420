<div [formGroup]="form">
  <p *ngIf="fecalInfo$ | async">{{ fecalInfo$ | async }}</p>
  <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayoutAlign="space-between" class="bottom-10">
    <div fxFlex.gt-md="23" fxFlex="47">
      <mat-form-field appearance="standard">
        <mat-label>💩 Positive Fecal Test Date</mat-label>
        <input
          matInput
          [matDatepicker]="positiveFecalTestDate"
          formControlName="positiveFecalTestDate"
          readonly
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="positiveFecalTestDate"
          disabled
        ></mat-datepicker-toggle>
        <mat-datepicker #positiveFecalTestDate disabled></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxFlex.gt-md="23" fxFlex="47">
      <mat-form-field appearance="standard">
        <mat-label>💩 Fecal Follow-up Test Due</mat-label>
        <input
          matInput
          [matDatepicker]="fecalFollowUpDate"
          formControlName="fecalFollowUpDate"
          readonly
        />
        <mat-datepicker-toggle matSuffix [for]="fecalFollowUpDate" disabled></mat-datepicker-toggle>
        <mat-datepicker #fecalFollowUpDate disabled></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxFlex.gt-md="23" fxFlex="47">
      <mat-form-field appearance="standard">
        <mat-label>✔️ Clear Fecal Test Date</mat-label>
        <input
          matInput
          [matDatepicker]="clearFecalTestDate"
          formControlName="clearFecalTestDate"
          readonly
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="clearFecalTestDate"
          disabled
        ></mat-datepicker-toggle>
        <mat-datepicker #clearFecalTestDate disabled></mat-datepicker>
      </mat-form-field>
    </div>
    <div
      fxFlex.gt-md="23"
      fxFlex="47"
      *ngIf="species !== speciesType.Dog || !isOverSixMonths"
    ></div>
    <div fxFlex.gt-md="23" fxFlex="47" *ngIf="species === speciesType.Dog && isOverSixMonths">
      <mat-form-field appearance="standard">
        <mat-label>✔️ Heartworm Test Completed</mat-label>
        <input
          matInput
          [matDatepicker]="heartwormTestingCompletedDate"
          formControlName="heartwormTestingCompletedDate"
          readonly
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="heartwormTestingCompletedDate"
          disabled
        ></mat-datepicker-toggle>
        <mat-datepicker #heartwormTestingCompletedDate disabled></mat-datepicker>
      </mat-form-field>
      <mat-checkbox
        color="primary"
        style="color: rgba(0, 0, 0, 0.6); margin-bottom: 8px"
        formControlName="heartwormPositive"
        disabled
        >Heartworm Positive</mat-checkbox
      >
    </div>
  </div>
  <p *ngIf="(preventionInfo$ | async) || (spayNeuterInfo$ | async)">
    {{ preventionInfo$ | async }} {{ spayNeuterInfo$ | async }}
  </p>
  <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayoutAlign="space-between" class="bottom-10">
    <div fxFlex.gt-md="23" fxFlex="47" *ngIf="species === speciesType.Cat">
      <mat-form-field appearance="standard">
        <mat-label>✔️ Combo Test Completed</mat-label>
        <input
          matInput
          [matDatepicker]="comboTestingCompletedDate"
          formControlName="comboTestingCompletedDate"
          readonly
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="comboTestingCompletedDate"
          disabled
        ></mat-datepicker-toggle>
        <mat-datepicker #comboTestingCompletedDate disabled></mat-datepicker>
      </mat-form-field>
      <mat-checkbox
        color="primary"
        style="color: rgba(0, 0, 0, 0.6); margin-bottom: 8px; margin-right: 16px"
        formControlName="fivPositive"
        disabled
        >FIV Positive</mat-checkbox
      >
      <mat-checkbox
        color="primary"
        style="color: rgba(0, 0, 0, 0.6); margin-bottom: 8px"
        formControlName="felvPositive"
        disabled
        >FeLV Positive</mat-checkbox
      >
    </div>
    <div fxFlex.gt-md="23" fxFlex="47" *ngIf="species === speciesType.Dog">
      <mat-form-field appearance="standard">
        <mat-label>💊 Next Heartworm Med Due</mat-label>
        <input
          matInput
          [matDatepicker]="nextHeartwormMedDue"
          formControlName="nextHeartwormMedDue"
          readonly
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="nextHeartwormMedDue"
          disabled
        ></mat-datepicker-toggle>
        <mat-datepicker #nextHeartwormMedDue disabled></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxFlex.gt-md="23" fxFlex="47" *ngIf="species !== null">
      <mat-form-field appearance="standard">
        <mat-label
          >💊 Next {{ species === speciesType.Cat ? 'Meds' : 'Flea/Tick Med' }} Due</mat-label
        >
        <input
          matInput
          [matDatepicker]="nextFleaTickMedDue"
          formControlName="nextFleaTickMedDue"
          readonly
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="nextFleaTickMedDue"
          disabled
        ></mat-datepicker-toggle>
        <mat-datepicker #nextFleaTickMedDue disabled></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxFlex.gt-md="23" fxFlex="47">
      <mat-form-field appearance="standard">
        <mat-label>✂️ Spay/Neuter Date</mat-label>
        <input matInput [matDatepicker]="fixed" formControlName="fixedOnDate" readonly />
        <mat-datepicker-toggle matSuffix [for]="fixed" disabled></mat-datepicker-toggle>
        <mat-datepicker #fixed disabled></mat-datepicker>
      </mat-form-field>
      <mat-checkbox
        color="primary"
        style="color: rgba(0, 0, 0, 0.6)"
        formControlName="fixedBeforeIntake"
        disabled
        >Fixed before intake</mat-checkbox
      >
    </div>
    <div fxFlex.gt-md="23" fxFlex="47">
      <mat-form-field *ngIf="!hideMicrochip" appearance="standard">
        <mat-label>🏷️ Microchip ID</mat-label>
        <input matInput type="text" formControlName="microchipId" readonly />
      </mat-form-field>
    </div>
    <div fxFlex.gt-md="23" fxFlex="47"></div>
    <div fxFlex.gt-md="23" fxFlex="47"></div>
  </div>
</div>
