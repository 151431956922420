import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { AppState } from 'web/app/core/app-state';
import { DoBulkUpdateAction } from 'web/app/foster/foster-store/foster.actions';
import { FosterState } from 'web/app/foster/foster-store/foster.state';
import { HotkeysService } from 'web/app/hotkeys.service';
import { Foster, Species } from 'web/app/models/foster.model';
import { User } from 'web/app/models/user.model';
import { SavePageComponent } from 'web/app/save-page/save-page.component';
import { RescuebaseValidators } from 'web/app/shared/validators';
import { environment } from 'web/environments/environment';

@Component({
  selector: 'app-bulk-update',
  templateUrl: './bulk-update.component.html',
  styleUrls: ['./bulk-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkUpdateComponent extends SavePageComponent implements OnInit, OnDestroy {
  @Select(FosterState.selected) selected$: Observable<Foster[]>;
  @Select(FosterState.processing) processing$: Observable<boolean>;

  form: FormGroup;
  baseUri = environment.apiUri + '/foster';
  // @ts-expect-error FIXME
  species: Species = null;
  birthDate: Date;
  Species = Species;
  names: string;
  currentUser: User;
  selected: Foster[];

  get showFosterInfo(): boolean {
    return (
      this.currentUser.isAdmin || this.selected.every((x) => this.currentUser.id === x.user.id)
    );
  }

  get showAdoptionInfo(): boolean {
    return this.currentUser.isAdmin || this.currentUser.isAdoptionCoordinator;
  }

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private router: Router,
    protected hotKeys: HotkeysService
  ) {
    super(hotKeys);

    this.selected = this.store.selectSnapshot<Foster[]>((state: AppState) => state.foster.selected);
    this.names = this.selected.map((x) => x.name).join(', ');
    if (!this.selected.length) {
      this.router.navigate(['/foster/list']);
    }
    const selectedSpecies = this.selected
      .map((x) => x.species)
      .filter((species, i, ar) => ar.indexOf(species) === i);
    if (selectedSpecies.length === 1) {
      this.species = selectedSpecies[0];
    }
    const selectedBirthdates = this.selected
      .map((x) => x.birthDate)
      .filter((birthDate, i, ar) => ar.indexOf(birthDate) === i);
    if (selectedBirthdates.length === 1) {
      this.birthDate = selectedBirthdates[0];
    }
    this.form = this.fb.group({
      user: null,
      species: null,
      breed: null,
      birthDate: [null, RescuebaseValidators.validDate],
      intakeDate: [null, RescuebaseValidators.validDate],
      currentFood: null,

      heartwormTestingCompletedDate: [null, RescuebaseValidators.validDate],
      heartwormPositive: null,
      comboTestingCompletedDate: [null, RescuebaseValidators.validDate],
      fivPositive: null,
      felvPositive: null,
      nextHeartwormMedDue: [null, RescuebaseValidators.validDate],
      nextFleaTickMedDue: [null, RescuebaseValidators.validDate],
      nextVaccinesDue: [null, RescuebaseValidators.validDate],
      fixedOnDate: [null, RescuebaseValidators.validDate],
      fixedBeforeIntake: null,
      positiveFecalTestDate: [null, RescuebaseValidators.validDate],
      clearFecalTestDate: [null, RescuebaseValidators.validDate],
      fecalFollowUpDate: [null, RescuebaseValidators.validDate],
      notes: null,
      readyForAdoption: null,
      isAdopted: null,
      weight: null,
    });
    this.currentUser = this.store.selectSnapshot<User>((state: AppState) => state.auth.user);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  save(): void {
    this.store.dispatch(
      new DoBulkUpdateAction({ ...this.form.getRawValue(), ids: this.selected.map((x) => x.id) })
    );
  }

  trackByFn(index: number, foster: Foster): string {
    return foster.id;
  }
}
