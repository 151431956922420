/* eslint-disable indent */
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Filter, FilterOperator, FilterType } from 'web/app/models/foster-filter.model';
import { Gender, Species } from 'web/app/models/foster.model';

@Component({
  selector: 'app-advanced-filter',
  templateUrl: './advanced-filter.component.html',
  styleUrls: ['./advanced-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedFilterComponent implements OnChanges, OnDestroy {
  @Input() isAdmin: boolean;
  @Input() advancedFilters: Filter[];
  @Output() changeAdvancedFilters = new EventEmitter<Filter[]>();

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  advancedFiltersForm = new FormArray([]);
  private destroy$ = new Subject<boolean>();

  operators = {
    [FilterType.Date]: [
      { name: 'is after', value: FilterOperator.GreaterThan },
      { name: 'is before', value: FilterOperator.LessThan },
      { name: 'exists', value: FilterOperator.NotEquals }, // the server parses this as `not equals empty`
      { name: 'does not exist', value: FilterOperator.Equals }, // the server parses this as `equals empty`
    ],
    [FilterType.Boolean]: [
      { name: 'is true', value: FilterOperator.Equals },
      { name: 'is false', value: FilterOperator.NotEquals },
    ],
    [FilterType.String]: [
      { name: 'contains', value: FilterOperator.Contains },
      { name: 'does not contain', value: FilterOperator.DoesNotContain },
      { name: 'exists', value: FilterOperator.NotEquals }, // the server parses this as `not equals empty`
      { name: 'does not exist', value: FilterOperator.Equals }, // the server parses this as `equals empty`
    ],
    [FilterType.Enum]: [
      { name: 'is', value: FilterOperator.Equals },
      { name: 'is not', value: FilterOperator.NotEquals },
    ],
  };

  filterSchema = [
    {
      title: 'Adopted',
      field: 'isAdopted',
      type: FilterType.Boolean,
    },
    {
      title: 'Adopted Name',
      field: 'adoptions.adoptedName',
      type: FilterType.String,
    },
    {
      title: 'Adoption Date',
      field: 'adoptions.0.adoptionDate',
      type: FilterType.Date,
    },
    {
      title: 'Adoption Return Date',
      field: 'adoptions.0.returnDate',
      type: FilterType.Date,
    },
    {
      title: 'Adopter Email',
      field: 'adoptions.adoptedByEmail',
      type: FilterType.String,
    },
    {
      title: 'Adopter Name',
      field: 'adoptions.adoptedByName',
      type: FilterType.String,
    },
    {
      title: 'Birth Date',
      field: 'birthDate',
      type: FilterType.Date,
    },
    {
      title: 'Breed',
      field: 'breed',
      type: FilterType.String,
    },
    {
      title: 'Combo Testing Completed',
      field: 'comboTestingCompletedDate',
      type: FilterType.Date,
    },
    {
      title: 'Deceased Date',
      field: 'deceasedDate',
      type: FilterType.Date,
    },
    {
      title: 'Fixed Before Intake',
      field: 'fixedBeforeIntake',
      type: FilterType.Boolean,
    },
    {
      title: 'Fixed On',
      field: 'fixedOnDate',
      type: FilterType.Date,
    },
    {
      title: 'Flea/Tick Meds Due',
      field: 'nextFleaTickMedDue',
      type: FilterType.Date,
    },
    {
      title: 'Gender',
      field: 'gender',
      type: FilterType.Enum,
      enumType: Gender,
    },
    {
      title: 'Heartworm Meds Due',
      field: 'nextHeartwormMedDue',
      type: FilterType.Date,
    },
    {
      title: 'Heartworm Positive',
      field: 'heartwormPositive',
      type: FilterType.Boolean,
    },
    {
      title: 'Heartworm Testing Complete',
      field: 'heartwormTestingCompletedDate',
      type: FilterType.Date,
    },
    {
      title: 'Hospice',
      field: 'isInHospice',
      type: FilterType.Boolean,
    },
    {
      title: 'Intake Date',
      field: 'intakeDate',
      type: FilterType.Date,
    },
    {
      title: 'Microchip Number',
      field: 'microchipId',
      type: FilterType.String,
    },
    {
      title: 'Name',
      field: 'name',
      type: FilterType.String,
    },
    {
      title: 'Ready For Adoption',
      field: 'readyForAdoption',
      type: FilterType.Boolean,
    },
    {
      title: 'Species',
      field: 'species',
      type: FilterType.Enum,
      enumType: Species,
    },
    {
      title: 'Updated At',
      field: 'updatedAt',
      type: FilterType.Date,
    },
    {
      title: 'Updated By',
      field: 'updatedBy',
      type: FilterType.String,
    },
    {
      title: 'Volunteer Name',
      field: 'userMeta.name',
      type: FilterType.String,
    },
  ];

  /* Only allow filtering by any given field once */
  get remainingOptions() {
    return this.filterSchema.filter(
      (x) =>
        // @ts-expect-error FIXME
        !this.advancedFiltersForm.controls.find((y) => y.get('field')?.value?.field === x.field)
    );
  }

  canFilterValidator(): ValidatorFn {
    // @ts-expect-error FIXME
    return (group: FormGroup): ValidationErrors => {
      const field = group.controls.field;
      const operator = group.controls.operator;
      const operands = group.controls.operands;

      const filterType = field.value?.type;

      if (!field.value) {
        field.setErrors({ missingField: true });
      } else {
        field.setErrors(null);
      }

      switch (filterType) {
        case FilterType.Enum:
          if (operands.value.length === 0) {
            operands.setErrors({ requiredOperands: true });
          }
          // @ts-expect-error FIXME
          return;
        case FilterType.Date:
        case FilterType.String:
          if (
            operator.value === FilterOperator.Equals ||
            operator.value === FilterOperator.NotEquals
          ) {
            operands.setErrors(null);
          } else if (operands.value?.length === 0) {
            operands.setErrors({ requiresOperands: true });
          }
          break;
        default:
      }
    };
  }

  constructor(private fb: FormBuilder) {
    // @ts-expect-error FIXME
    this.filterSchema.map((schema) => (schema.operators = this.operators[schema.type]));
    this.initForm([]);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.advancedFilters?.currentValue && this.advancedFiltersForm?.invalid) {
      this.initForm(changes.advancedFilters.currentValue);
    }
  }

  initForm(filters: Filter[]) {
    // @ts-expect-error FIXME
    this.advancedFiltersForm = this.fb.array([]);

    if (filters.length) {
      filters.forEach((filter) => {
        this.newAdvancedFilterGroup(filter);
      });
    } else {
      this.newAdvancedFilterGroup();
    }

    this.advancedFiltersForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((form) => {
      for (let i = 0; i < form.length; i++) {
        // @ts-expect-error FIXME
        if (!form[i].field) {
          // @ts-expect-error FIXME
          this.advancedFiltersForm.controls[i].get('operator').disable({ emitEvent: false });
        } else {
          // @ts-expect-error FIXME
          this.advancedFiltersForm.controls[i].get('operator').enable({ emitEvent: false });
        }
      }

      if (this.advancedFiltersForm.invalid) {
        return;
      }

      this.emitChange();
    });
  }

  emitChange() {
    const filters = [];

    this.advancedFiltersForm.getRawValue().forEach((filter) => {
      // @ts-expect-error FIXME
      if (filter.field && filter.field.type === FilterType.Boolean) {
        // @ts-expect-error FIXME
        if (filter.operator === FilterOperator.Equals) {
          // @ts-expect-error FIXME
          filter.operands = true;
        } else {
          // @ts-expect-error FIXME
          filter.operands = false;
          // @ts-expect-error FIXME
          filter.operator = FilterOperator.Equals;
        }
      }

      // @ts-expect-error FIXME
      if (filter.field !== null) {
        // @ts-expect-error FIXME
        filters.push({
          // @ts-expect-error FIXME
          field: filter.field?.field,
          // @ts-expect-error FIXME
          operator: filter.operator,
          // @ts-expect-error FIXME
          operands: filter.operands,
        });
      }
    });

    this.changeAdvancedFilters.emit(filters);
  }

  shouldShowStringField(formGroup) {
    const operator = formGroup.get('operator').value;
    const field = formGroup.get('field').value;

    return (
      field &&
      field.type === FilterType.String &&
      (operator === FilterOperator.Contains || operator === FilterOperator.DoesNotContain)
    );
  }

  shouldShowDateField(formGroup) {
    const operator = formGroup.get('operator').value;
    const field = formGroup.get('field').value;

    return (
      field &&
      field.type === FilterType.Date &&
      (operator === FilterOperator.GreaterThan || operator === FilterOperator.LessThan)
    );
  }

  shouldShowEnumField(formGroup) {
    const field = formGroup.get('field').value;

    return field && field.type === FilterType.Enum;
  }

  newAdvancedFilterGroup(filter?: Filter) {
    const filterSchema = !filter ? null : this.filterSchema.find((x) => x.field === filter.field);

    let operands;
    if (
      typeof filter?.operands === 'number' ||
      typeof filter?.operands === 'boolean' ||
      filter?.operands.length
    ) {
      operands = filter?.operands;
    } else {
      operands = [];
    }

    const group = this.fb.group({
      field: new FormControl(filterSchema, Validators.required),
      operator: new FormControl(
        { value: filter?.operator, disabled: !filter?.field },
        Validators.required
      ),
      operands: new FormControl(operands),
    });

    group.setValidators(this.canFilterValidator());

    // @ts-expect-error FIXME
    this.advancedFiltersForm.push(group);
  }

  removeOperandChip(index, value) {
    const operandsControl = (this.advancedFiltersForm.controls[index] as FormGroup).controls
      .operands;
    const operands = [...operandsControl.value];
    const i = operands.indexOf(value);

    if (i >= 0) {
      operands.splice(i, 1);
    }

    operandsControl.patchValue(operands);
  }

  addOperandChip(index, $event) {
    const operands = (this.advancedFiltersForm.controls[index] as FormGroup).controls.operands
      .value;

    if ($event.value) {
      (this.advancedFiltersForm.controls[index] as FormGroup).controls.operands.setValue([
        ...operands,
        $event.value,
      ]);
    }

    $event.input.value = '';
  }

  addFilter() {
    this.newAdvancedFilterGroup();
  }

  removeFilter(index) {
    if (this.advancedFiltersForm.controls.length === 1) {
      // @ts-expect-error FIXME
      this.advancedFiltersForm.at(index).reset();
    } else {
      this.advancedFiltersForm.removeAt(index);
    }
    this.emitChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
