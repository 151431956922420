<mat-toolbar class="browser-warning" *ngIf="!browserSupported">
  <h1>Upgrade your browser for a better experience</h1>
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav
    [mode]="showSidenavOver ? 'over' : 'side'"
    [(opened)]="navOpen"
    [fixedInViewport]="true"
    *ngIf="(loggedIn$ | async) && !(loading$ | async)"
  >
    <div class="sidenav-container" fxLayout="column" fxLayoutAlign="space-between">
      <div class="sidenav-content">
        <img class="logo" width="100%" src="../assets/logo.svg" alt="Rescuebase Logo" />
        <div class="nav-list" fxLayout="column">
          <a matRipple matRippleColor="accent" routerLink="/foster/list" routerLinkActive="active"
            >Home</a
          >
          <a matRipple matRippleColor="accent" routerLink="/whats-next" routerLinkActive="active"
            >What's Next</a
          >
          <a matRipple matRippleColor="accent" routerLink="/dashboard" routerLinkActive="active"
            >Dashboard</a
          >
          <a
            matRipple
            matRippleColor="accent"
            *ngIf="isAdmin$ | async"
            routerLinkActive="active"
            routerLink="/user/list"
            >Users</a
          >
          <a
            *ngIf="isAdmin$ | async"
            matRipple
            matRippleColor="accent"
            routerLink="organization"
            routerLinkActive="active"
            >Organization</a
          >
          <a matRipple matRippleColor="accent" routerLink="settings" routerLinkActive="active"
            >Settings</a
          >
          <a matRipple matRippleColor="accent" routerLink="send-feedback" routerLinkActive="active"
            >Send Feedback</a
          >
        </div>
      </div>
      <div fxFlex></div>
      <div *ngIf="user$ | async" class="user-info" fxLayout="column" fxLayoutAlign="end center">
        <a routerLink="." (click)="signOut()">Sign Out</a>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <button
      *ngIf="(loggedIn$ | async) && !(loading$ | async)"
      class="sidenav-toggle"
      ngClass.xs="small"
      mat-icon-button
      (click)="navOpen = !navOpen"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <div *ngIf="loading$ | async" fxLayoutAlign="space-around center">
      <div class="loading-container" fxLayout="column" fxLayoutAlign="start center">
        <app-paw-loader></app-paw-loader>
      </div>
    </div>
    <div *ngIf="!(loading$ | async)" class="outlet-container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<router-outlet name="print"></router-outlet>
