<div class="container" fxLayout="row" fxLayoutAlign="space-around center">
  <mat-card>
    <h1 class="top-0 text-center">
      <img class="logo" src="../assets/logo.svg" alt="Rescuebase Logo" />
    </h1>

    <p>
      Looks like you don't have an account yet.<br />
      Ask your organization's administrator to invite you.
    </p>
    <p class="top-30">Wrong account?</p>
    <button type="button" mat-flat-button color="primary" (click)="returnToLogin()">
      Return to Login
    </button>
    <p class="contact top-30">
      Still need help? Contact <a href="mailto:support@rescuebase.io">support@rescuebase.io</a>
    </p>
  </mat-card>
</div>
