import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';

import { AuthState } from 'web/app/auth/store/auth.state';
import { AppState } from 'web/app/core/app-state';
import {
  ChangeAdvancedFiltersAction,
  ChangeFilterAction,
  ChangeSearchAction,
  ExportAllAction,
  LoadFostersAction,
  LoadMoreAction,
  ToggleSelectAction,
} from 'web/app/foster/foster-store/foster.actions';
import { FosterState } from 'web/app/foster/foster-store/foster.state';
import { Filter, FosterFilter } from 'web/app/models/foster-filter.model';
import { Foster } from 'web/app/models/foster.model';
import { User } from 'web/app/models/user.model';

@Component({
  selector: 'app-foster-list',
  templateUrl: './foster-list.component.html',
  styleUrls: ['./foster-list.component.scss'],
})
export class FosterListComponent implements OnDestroy {
  @Select(FosterState.fosters) fosters$: Observable<Foster[]>;
  @Select(FosterState.loadingFosters) loading$: Observable<boolean>;
  @Select(FosterState.total) total$: Observable<number>;
  @Select(FosterState.advancedFilters) advancedFilters$: Observable<Filter[]>;
  @Select(FosterState.filter) filter$: Observable<FosterFilter>;
  @Select(FosterState.search) search$: Observable<string>;
  @Select(FosterState.showUserName) showUserName$: Observable<boolean>;
  @Select(FosterState.selected) selected$: Observable<Foster[]>;
  @Select(AuthState.isAdmin) isAdmin$: Observable<boolean>;
  @Select(AuthState.user) user$: Observable<User>;

  showFilters = false;
  private destroy$ = new Subject<boolean>();

  constructor(private store: Store, private router: Router) {
    this.store.dispatch(new LoadFostersAction());
  }

  itemHash(index, item): string {
    return `${item.name}${item.user?.id}${item.thumbUri}`;
  }

  addFoster(): void {
    this.router.navigate(['/foster/add']).then();
  }

  edit(id: string): void {
    this.router.navigate(['foster', 'detail', id]).then();
  }

  loadMore(): void {
    this.store.dispatch(new LoadMoreAction());
  }

  handleTileClick(foster: Foster): void {
    if (this.showFilters) {
      return;
    }

    const selected = this.store.selectSnapshot<Foster[]>(
      (state: AppState) => state.foster.selected
    );
    if (selected?.length) {
      this.select(foster);
    } else {
      this.edit(foster.id);
    }
  }

  select(foster: Foster): void {
    const user = this.store.selectSnapshot<User>((state: AppState) => state.auth.user);
    if (user.isAdmin || user.id === foster.user?.id) {
      this.store.dispatch(new ToggleSelectAction(foster));
    }
  }

  exportAll(): void {
    this.store.dispatch(new ExportAllAction());
  }

  changeSearch(search: string): void {
    this.store.dispatch(new ChangeSearchAction(search));
  }

  changeFilter(filter): void {
    this.store.dispatch(new ChangeFilterAction(filter));
  }

  changeAdvancedFilters(filter): void {
    this.store.dispatch(new ChangeAdvancedFiltersAction(filter));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
