import { MedicalInfo, Organization } from 'web/app/models/organization.model';
import { Vaccine } from 'web/app/models/vaccine.model';

export class GetOrganizationAction {
  static readonly type = '[Org] Get Organization';
}

export class SaveOrganizationAction {
  static readonly type = '[Organization] Save Organization';
  constructor(public organization: Organization) {}
}

export class SaveVaccineAction {
  static readonly type = '[Organization] Save Vaccine';
  constructor(public vaccine: Vaccine) {}
}

export class DeleteVaccineAction {
  static readonly type = '[Organization] Delete Vaccine';
  constructor(public id: string) {}
}

export class SaveMedicalInfoAction {
  static readonly type = '[Organization] Save Medical Info';
  constructor(public medicalInfo: MedicalInfo) {}
}

export class DeleteMedicalInfoAction {
  static readonly type = '[Organization] Delete Medical Info';
  constructor(public id: string) {}
}
