<div fxLayout="row wrap" [formGroup]="form" class="top-20">
  <mat-slide-toggle formControlName="readyForAdoption">Ready For Adoption</mat-slide-toggle>
  <mat-slide-toggle
    *ngIf="currentUser?.isAdmin || currentUser?.isAdoptionCoordinator"
    formControlName="isAdopted"
    class="bottom-5"
    >Adopted</mat-slide-toggle
  >
</div>

<div
  *ngIf="fosterId"
  fxLayout="row wrap"
  fxLayoutAlign="space-between"
  [class.top-20]="adoptions.length"
>
  <div fxFlex="100" fxFlex.gt-sm="100">
    <div fxShow.xs fxHide.gt-xs>
      <div *ngFor="let adoption of adoptions" fxLayout="row" fxLayoutAlign="space-between">
        <div class="container">
          <p class="name">
            {{ adoption.adoptedByName }}
            <span *ngIf="adoption.adoptedName">({{ adoption.adoptedName }})</span>
          </p>
          <p>
            {{ adoption.adoptionDate | date: 'shortDate' }}
            <span *ngIf="adoption.returnDate">
              &mdash; {{ adoption.returnDate | date: 'shortDate' }}</span
            >
          </p>
        </div>
        <div class="button-row">
          <button
            *ngIf="!disabled"
            type="button"
            mat-icon-button
            color="primary"
            [matMenuTriggerFor]="menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="edit(adoption)">Edit</button>
            <button *ngIf="!adoption?.returnDate" mat-menu-item (click)="markReturned(adoption)">
              Mark as Returned
            </button>
            <button mat-menu-item (click)="confirmDelete(adoption)">Delete</button>
          </mat-menu>
        </div>
      </div>
    </div>
    <div fxHide.xs>
      <table *ngIf="adoptions?.length" mat-table [dataSource]="adoptions">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <ng-container matColumnDef="adoptedBy">
          <th mat-header-cell *matHeaderCellDef>Adopted By</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.adoptedByName }}<br /><small>{{ element?.adoptedByEmail }}</small>
          </td>
        </ng-container>

        <ng-container matColumnDef="adoptionDate">
          <th mat-header-cell *matHeaderCellDef>Adoption Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.adoptionDate | date }}
          </td>
        </ng-container>

        <ng-container matColumnDef="adoptedName">
          <th mat-header-cell *matHeaderCellDef>Pet's Adopted Name</th>
          <td mat-cell *matCellDef="let element">{{ element?.adoptedName }}</td>
        </ng-container>

        <ng-container matColumnDef="fosteredBy">
          <th mat-header-cell *matHeaderCellDef>Fostered By</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.user?.firstName }} {{ element?.user?.lastName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="returnDate">
          <th mat-header-cell *matHeaderCellDef>Return Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.returnDate | date }}
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button type="button" mat-icon-button color="primary" [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="edit(element)">Edit</button>
              <button *ngIf="!element?.returnDate" mat-menu-item (click)="markReturned(element)">
                Mark as Returned
              </button>
              <button *ngIf="canDelete" mat-menu-item (click)="confirmDelete(element)">
                Delete
              </button>
            </mat-menu>
          </td>
        </ng-container>
      </table>
    </div>
  </div>
</div>
<button
  *ngIf="showAddButton"
  type="button"
  mat-raised-button
  color="primary"
  style="margin-top: 20px"
  (click)="create()"
>
  Add
</button>
