import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrganizationState } from 'web/app/core/organization-store/organization.state';
import { Species } from 'web/app/models/foster.model';
import { Vaccine } from 'web/app/models/vaccine.model';

@Component({
  selector: 'app-vaccinations',
  templateUrl: './vaccinations.component.html',
  styleUrls: ['./vaccinations.component.scss'],
})
export class VaccinationsComponent implements OnChanges {
  @Input() fosterId: string;
  @Input() form: FormGroup;
  @Input() species: Species;
  @Input() birthDate: Date;
  @Input() disabled: boolean;
  @Input() processing: boolean;
  @Input() processingVaccinations: boolean;
  @Input() isMissingBirthdate: boolean;

  @Output() create = new EventEmitter<void>();
  @Output() delete = new EventEmitter<number>();
  @Output() processVaccinations = new EventEmitter<void>();

  vaccinationInfo$: Observable<string>;
  vaccines$: Observable<Vaccine[]>;

  get vaccinations(): FormArray {
    return this.form?.get('vaccinations') as FormArray;
  }

  constructor(private store: Store) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.species || changes.birthDate) {
      this.vaccinationInfo$ = this.store
        .select(OrganizationState.vaccinationInfo)
        .pipe(map((fn) => fn(this.species, this.birthDate)));
      this.vaccines$ = this.store
        .select(OrganizationState.vaccinesForSpecies)
        .pipe(map((fn) => fn(this.species)));
    }
  }
}
