import { ErrorHandler, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as Sentry from '@sentry/browser';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class RbErrorHandler implements ErrorHandler {
  constructor(private snackbar: MatSnackBar, private logger: NGXLogger) {}

  handleError(error: any) {
    if (error?.isHandled) {
      return;
    }

    this.logger.error(error);

    if (
      !error ||
      (error.message &&
        typeof error.message === 'string' &&
        !error.message.includes('Extension context')) ||
      (typeof error === 'string' && !error.includes('Extension context'))
    ) {
      if (!(error instanceof Error)) {
        Sentry.captureException(new Error(error));
      } else {
        Sentry.captureException(error);
      }
    }

    return this.snackbar.open(error?.error ? error.error : error, 'DISMISS', {
      politeness: 'assertive',
      duration: 0,
      panelClass: 'snack-bar-error',
    });
  }
}
