<mat-dialog-content>
  <div class="close-container">
    <button mat-icon-button mat-dialog-close class="close-button" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <h1>{{ startReturn ? 'Return' : 'Adoption' }} Info</h1>
  <form [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutAlign="space-between">
      <div *ngIf="!startReturn" fxFlex="100" fxFlex.gt-xs="47">
        <mat-form-field appearance="standard">
          <mat-label>Adopted By</mat-label>
          <input matInput formControlName="adoptedByName" />
        </mat-form-field>
      </div>
      <div *ngIf="!startReturn" fxFlex="100" fxFlex.gt-xs="47">
        <mat-form-field appearance="standard">
          <mat-label>Adopter's Email</mat-label>
          <input matInput type="email" formControlName="adoptedByEmail" />
          <mat-error *ngIf="form.get('adoptedByEmail')['invalid']"
            >Please enter a valid email</mat-error
          >
        </mat-form-field>
      </div>
      <div *ngIf="!startReturn" fxFlex="100" fxFlex.gt-xs="25">
        <mat-form-field appearance="standard">
          <mat-label>Adoption Date</mat-label>
          <input matInput [matDatepicker]="adoptionDate" formControlName="adoptionDate" />
          <mat-datepicker-toggle matSuffix [for]="adoptionDate"></mat-datepicker-toggle>
          <mat-datepicker #adoptionDate></mat-datepicker>
          <mat-error *ngIf="form.get('adoptionDate')['invalid']"
            >Please enter a valid date</mat-error
          >
        </mat-form-field>
      </div>
      <div *ngIf="!startReturn" fxFlex="100" fxFlex.gt-xs="25">
        <mat-form-field appearance="standard">
          <mat-label>Payment Method</mat-label>
          <mat-select formControlName="paymentMethod">
            <mat-option *ngFor="let p of paymentMethod | enumToList" [value]="p.id">{{
              p.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="!startReturn" fxFlex="100" fxFlex.gt-xs="42">
        <mat-form-field appearance="standard">
          <mat-label>Pet's Adopted Name (if different)</mat-label>
          <input matInput formControlName="adoptedName" />
        </mat-form-field>
      </div>
      <div *ngIf="hasReturnDate || startReturn" fxFlex="100" fxFlex.gt-xs="47">
        <mat-form-field appearance="standard">
          <mat-label>Return Date</mat-label>
          <input matInput [matDatepicker]="returnDate" formControlName="returnDate" />
          <mat-datepicker-toggle matSuffix [for]="returnDate"></mat-datepicker-toggle>
          <mat-datepicker #returnDate></mat-datepicker>
          <mat-error *ngIf="form.get('returnDate')['invalid']">Please enter a valid date</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="hasReturnDate || startReturn" fxFlex="100">
        <mat-form-field appearance="standard">
          <mat-label>Reason for Return</mat-label>
          <input matInput formControlName="returnReason" />
        </mat-form-field>
      </div>
      <div *ngIf="!startReturn && data.adoption?.id" fxFlex="100">
        <app-user-select [form]="form"></app-user-select>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end" class="top-20">
      <button *ngIf="editMode" mat-button mat-dialog-close>Cancel</button>
      <button
        mat-button
        tabindex="-1"
        color="primary"
        [disabled]="form.invalid || form.pristine"
        (click)="save()"
      >
        Save
      </button>
    </div>
  </form>
</mat-dialog-content>
