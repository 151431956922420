<h3 *ngIf="vaccinationInfo$ | async" ngClass.gt-sm="bottom-20 top-40">Vaccinations</h3>
<p *ngIf="vaccinationInfo$ | async">{{ vaccinationInfo$ | async }}</p>
<div [formGroup]="form">
  <div
    formArrayName="vaccinations"
    *ngFor="let vaccination of vaccinations.controls; let i = index; let last = last"
  >
    <app-vaccination
      [vaccination]="vaccination"
      [vaccines]="vaccines$ | async"
      [disabled]="disabled"
      (delete)="delete.emit(i)"
      [last]="last"
    ></app-vaccination>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="start center" class="actions" *ngIf="vaccinationInfo$ | async">
  <button
    type="button"
    mat-raised-button
    [disabled]="disabled"
    color="primary"
    class="button-margin-right"
    (click)="create.emit()"
  >
    Add
  </button>
  <span
    class="button-container"
    [matTooltip]="
      isMissingBirthdate ? 'Enter a birthdate to auto-generate vaccination schedule' : null
    "
  >
    <button
      [disabled]="disabled || processing || processingVaccinations || isMissingBirthdate"
      type="button"
      mat-button
      color="primary"
      (click)="processVaccinations.emit()"
    >
      <span style="display: flex; align-items: center">
        {{ vaccinations?.controls?.length ? 'Update' : 'Auto-Generate' }} Schedule
        <mat-spinner
          *ngIf="processingVaccinations"
          diameter="14"
          style="margin-left: 4px"
        ></mat-spinner>
      </span>
    </button>
  </span>
</div>
