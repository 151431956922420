<div class="login-container" fxLayout="row" fxLayoutAlign="space-around">
  <div fxLayout="column" fxLayoutAlign="center center">
    <mat-card>
      <h1 class="text-center" [ngClass]="{ 'bottom-20': code, 'bottom-40': !code }">
        <img class="logo" width="75%" src="../assets/logo.svg" alt="Rescuebase Logo" />
      </h1>
      <div *ngIf="waitingOnOTP" fxLayout="column" fxLayoutAlign="space-around">
        <p>A temporary code has been sent to this email.</p>
        <form [formGroup]="otpForm" (ngSubmit)="otpSubmit()">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>One Time Password</mat-label>
            <input type="text" matInput [formControl]="otpFormControl" />
          </mat-form-field>
          <div fxLayout="column" fxLayoutAlign="space-around">
            <button
              mat-raised-button
              fxLayoutAlign="center center"
              type="button"
              color="primary"
              style="margin-top: 10px"
              type="submit"
              [disabled]="otpFormControl.invalid"
            >
              Login
            </button>
            <button
              mat-raised-button
              fxLayoutAlign="center center"
              type="button"
              style="margin-top: 10px"
              (click)="resendOTP()"
            >
              Resend Code
            </button>
            <button
              mat-button
              fxLayoutAlign="center center"
              type="button"
              style="margin-top: 10px"
              (click)="startOver()"
            >
              Start Over
            </button>
          </div>
        </form>
      </div>
      <div *ngIf="!waitingOnOTP" fxLayout="column" fxLayoutAlign="space-around">
        <form [formGroup]="loginForm" (ngSubmit)="login()">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Email</mat-label>
            <input
              type="email"
              matInput
              [formControl]="emailFormControl"
              [errorStateMatcher]="matcher"
              placeholder="Ex. johndoe@example.com"
            />
            <mat-error
              *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')"
            >
              Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
              Email is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <div fxLayout="row" fxLayoutAlign="space-around">
            <button
              mat-raised-button
              fxLayoutAlign="center center"
              type="button"
              color="primary"
              style="margin-top: 10px"
              type="submit"
              [disabled]="emailFormControl.invalid"
            >
              Login
            </button>
          </div>
        </form>
      </div>
      <div class="top-20">
        <a mat-button href="/static/privacy.html">Privacy Policy</a>
      </div>
    </mat-card>

    <mat-card class="info-card" style="margin-top: 20px; text-align: center">
      <p>
        Not from Pet Tales Rescue? Our new platform is coming soon at
        <a href="https://www.rescuebase.com" target="_blank" rel="noopener">www.rescuebase.com</a>
      </p>
    </mat-card>
  </div>
</div>
