import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngxs/store';

import { AppState } from 'web/app/core/app-state';
import { FosterAdoption } from 'web/app/models/foster-adoption.model';
import { DocumentType } from 'web/app/models/foster-document.model';
import { User } from 'web/app/models/user.model';
import { ConfirmDialogComponent } from 'web/app/shared/components/confirm-dialog/confirm-dialog.component';

import { AdoptionDialogComponent } from './adoption-dialog/adoption-dialog.component';

@Component({
  selector: 'app-adoptions',
  templateUrl: './adoptions.component.html',
  styleUrls: ['./adoptions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdoptionsComponent {
  @Input() adoptions: FosterAdoption[];
  @Input() fosterId: string;
  @Input() form: FormGroup;
  @Input() disabled: boolean;

  @Output() save = new EventEmitter<FosterAdoption>();
  @Output() delete = new EventEmitter<FosterAdoption>();

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  documentTypes = DocumentType;
  currentUser: User;

  get showAddButton(): boolean {
    // @ts-expect-error FIXME
    return (
      this.fosterId &&
      !this.disabled &&
      (!this.adoptions?.length || this.adoptions.every((x) => !!x.returnDate))
    );
  }

  get displayedColumns(): string[] {
    if (!this.adoptions?.length) return [];
    let cols = ['adoptedBy', 'adoptionDate', 'adoptedName'];
    if (this.adoptions.some((x) => x.returnDate)) {
      cols = cols.concat(['fosteredBy', 'returnDate']);
    }
    if (!this.disabled) {
      cols.push('actions');
    }
    return cols;
  }

  constructor(private matDialog: MatDialog, private store: Store) {
    this.currentUser = this.store.selectSnapshot((state: AppState) => state.auth.user);
  }

  create(): void {
    this.matDialog
      .open(AdoptionDialogComponent, {
        data: {},
        minWidth: '30vw',
        maxWidth: '95%',
        minHeight: '300px',
        panelClass: 'document-upload',
      })
      .afterClosed()
      .subscribe((adoption) =>
        this.handleSubscription<FosterAdoption>(!!adoption, adoption, this.save)
      );
  }

  edit(adoption: FosterAdoption): void {
    this.matDialog
      .open(AdoptionDialogComponent, {
        data: {
          adoption,
        },
        minWidth: '300px',
        minHeight: '300px',
        panelClass: 'document-upload',
      })
      .afterClosed()
      .subscribe((adoption) =>
        this.handleSubscription<FosterAdoption>(!!adoption, adoption, this.save)
      );
  }

  markReturned(adoption: FosterAdoption): void {
    this.matDialog
      .open(AdoptionDialogComponent, {
        data: {
          adoption,
          startReturn: true,
        },
        minWidth: '300px',
        minHeight: '300px',
        panelClass: 'document-upload',
      })
      .afterClosed()
      .subscribe((adoption) =>
        this.handleSubscription<FosterAdoption>(!!adoption, adoption, this.save)
      );
  }

  confirmDelete(adoption: FosterAdoption): void {
    this.matDialog
      .open(ConfirmDialogComponent, {
        data: {
          text: `Delete Adoption for ${adoption.adoptedByName}?`,
          confirmText: 'Delete',
          confirmColor: 'warn',
        },
      })
      .afterClosed()
      .subscribe((shouldContinue) =>
        this.handleSubscription<FosterAdoption>(!!shouldContinue, adoption, this.delete)
      );
  }

  private handleSubscription<T>(execute: boolean, payload: T, handler: EventEmitter<T>): void {
    if (execute) {
      handler.emit(payload);
    }
  }
}
