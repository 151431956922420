<div class="toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="header" *ngIf="id">{{ name?.value }}</div>
  <div class="header" *ngIf="!id">Add Foster</div>
  <div *ngIf="id" fxLayout="row" fxLayoutAlign="end center">
    <button
      mat-button
      [fxShow]="false"
      [fxShow.gt-sm]="true"
      [matMenuTriggerFor]="printMenu"
      disabled
    >
      Print
      <mat-icon class="menu-arrow">keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #printMenu="matMenu">
      <button mat-menu-item disabled>Cover Sheet</button>
      <button mat-menu-item disabled>Adoption Contract</button>
    </mat-menu>
    <button
      [fxShow]="false"
      [fxShow.gt-sm]="true"
      type="button"
      color="warn"
      class="button-margin-left"
      mat-button
      disabled
    >
      Delete
    </button>
    <button
      [fxShow]="false"
      [fxShow.gt-sm]="true"
      class="button-margin-left"
      color="primary"
      type="button"
      mat-button
      disabled
    >
      Mark Deceased
    </button>
    <button
      [fxShow]="false"
      [fxShow.gt-sm]="true"
      type="button"
      color="primary"
      class="button-margin-left"
      mat-button
      disabled
    >
      Copy
    </button>
    <app-save-status
      [status]="saveStatus"
      [updatedAt]="foster?.updatedAt"
      [updatedBy]="foster?.updatedBy"
      [disabled]="true"
    ></app-save-status>
    <button
      type="button"
      mat-icon-button
      color="primary"
      [matMenuTriggerFor]="menu"
      fxShow
      [fxShow.gt-sm]="false"
      disabled
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item disabled>Print Cover Sheet</button>
      <button mat-menu-item disabled>Print Adoption Contract</button>
      <button mat-menu-item disabled>Copy</button>
      <button mat-menu-item disabled>Mark Deceased</button>
      <button mat-menu-item disabled>Delete</button>
    </mat-menu>
  </div>
</div>
<div class="page-content">
  <app-loading-spinner *ngIf="loading"></app-loading-spinner>

  <div *ngIf="!loading">
    <form [formGroup]="form">
      <div
        fxLayout="column"
        fxLayout.gt-sm="row"
        fxLayoutAlign="space-between center"
        class="bottom-20"
      >
        <div fxFlex="100" fxFlex.gt-sm="300px">
          <div fxLayout="row" fxLayoutAlign="space-around">
            <app-image-upload
              [fosterId]="id"
              [imageUri]="imageUri"
              [disabled]="true"
              (upload)="uploadImage($event)"
            ></app-image-upload>
          </div>
        </div>
        <div fxFlex="20px"></div>
        <div fxFlex="calc(100% - 320px)">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <h3 class="top-0 bottom-0">Foster Info</h3>
            </mat-expansion-panel-header>
            <app-base-foster-info
              [form]="form"
              [currentUser]="currentUser"
              [weightDate]="foster?.weightDate"
            ></app-base-foster-info>
          </mat-expansion-panel>
        </div>
      </div>

      <section class="top-20">
        <mat-expansion-panel [expanded]="expandPanels">
          <mat-expansion-panel-header>
            <h3>Medical Info</h3>
          </mat-expansion-panel-header>
          <app-medical-info
            [form]="form"
            [species]="species.value"
            [birthDate]="birthDate.value"
          ></app-medical-info>
          <app-vaccinations
            [fosterId]="fosterId"
            [species]="species.value"
            [birthDate]="birthDate.value"
            [form]="form"
            [disabled]="true"
            [processing]="processing"
            [processingVaccinations]="processingVaccinations"
            [isMissingBirthdate]="!this.birthDate.value"
            (create)="createVaccination()"
            (delete)="deleteVaccination($event)"
            (processVaccinations)="processVaccinations()"
          ></app-vaccinations>
          <div
            *ngIf="currentUser?.isAdmin && foster?.deceasedDate"
            fxLayout="row"
            fxLayoutAlign="space-between"
          >
            <div fxFlex="47" fxFlex.gt-md="23">
              <mat-form-field appearance="standard">
                <mat-label>Date of Death</mat-label>
                <input matInput [matDatepicker]="deceasedDate" formControlName="deceasedDate" />
                <mat-datepicker-toggle matSuffix [for]="deceasedDate"></mat-datepicker-toggle>
                <mat-datepicker #deceasedDate></mat-datepicker>
                <mat-error *ngIf="form.get('deceasedDate')['invalid']"
                  >Please enter a valid date</mat-error
                >
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </section>

      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start stretch" class="top-20">
        <section fxFlex="40" fxLayout="column">
          <mat-expansion-panel [expanded]="expandPanels" style="height: 100%">
            <mat-expansion-panel-header>
              <h3>History & Training</h3>
            </mat-expansion-panel-header>

            <mat-form-field appearance="standard">
              <mat-label>History</mat-label>
              <textarea
                matInput
                formControlName="history"
                cdkTextareaAutosize
                [cdkAutosizeMinRows]="5"
                readonly
              ></textarea>
              <mat-hint>Ex. Where they came from, any background info</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="standard">
              <mat-label>Training</mat-label>
              <textarea
                matInput
                formControlName="training"
                cdkTextareaAutosize
                [cdkAutosizeMinRows]="5"
                readonly
              ></textarea>
              <mat-hint>Ex. House-trained, crate-trained, etc.</mat-hint>
            </mat-form-field>
          </mat-expansion-panel>
        </section>
        <div fxFlex="20px"></div>
        <section fxFlex="60" fxLayout="column">
          <mat-expansion-panel [expanded]="expandPanels">
            <mat-expansion-panel-header>
              <h3>Notes</h3>
            </mat-expansion-panel-header>
            <mat-form-field appearance="standard">
              <mat-label>Notes</mat-label>
              <textarea
                matInput
                formControlName="notes"
                cdkTextareaAutosize
                [cdkAutosizeMinRows]="7"
                readonly
              ></textarea>
            </mat-form-field>
            <mat-form-field appearance="standard">
              <mat-label>Private Notes</mat-label>
              <textarea
                matInput
                formControlName="privateNotes"
                cdkTextareaAutosize
                [cdkAutosizeMinRows]="3"
                readonly
              ></textarea>
              <mat-hint
                >These notes will <i>not</i> be shown on the printout for the adopter</mat-hint
              >
            </mat-form-field>
          </mat-expansion-panel>
        </section>
      </div>

      <section class="top-20">
        <mat-expansion-panel [expanded]="expandPanels">
          <mat-expansion-panel-header>
            <h3>Adoption Info</h3>
          </mat-expansion-panel-header>
          <app-adoptions
            [form]="form"
            [adoptions]="adoptions"
            [fosterId]="foster?.id"
            [disabled]="true"
            [canDelete]="false"
            (delete)="deleteAdoption($event)"
            (save)="saveAdoption($event)"
          >
          </app-adoptions>
        </mat-expansion-panel>
      </section>

      <section class="top-20">
        <mat-expansion-panel [expanded]="expandPanels">
          <mat-expansion-panel-header>
            <h3>Documents</h3>
          </mat-expansion-panel-header>
          <app-documents
            [fosterId]="foster?.id"
            [documents]="documents"
            [disabled]="true"
            (upload)="createDocument($event)"
            (download)="downloadFile($event)"
            (save)="saveDocument($event)"
            (delete)="deleteDocument($event)"
          >
          </app-documents>
        </mat-expansion-panel>
      </section>
    </form>
  </div>
</div>
