import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { Vaccine } from 'web/app/models/vaccine.model';
import { ConfirmDialogComponent } from 'web/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-vaccination',
  templateUrl: './vaccination.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./vaccination.component.scss'],
})
export class VaccinationComponent {
  @Input() vaccination: FormGroup;
  @Input() vaccines: Vaccine[];
  @Input() disabled: boolean;
  @Input() last: boolean;

  @Output() delete = new EventEmitter<void>();

  editing = false;

  get givenDate(): AbstractControl | null {
    return this.vaccination.get('givenDate');
  }

  get dueDate(): AbstractControl | null {
    return this.vaccination.get('dueDate');
  }

  get vaccine(): Vaccine | undefined {
    return this.vaccines?.find((x) => x.id === this.vaccination.get('vaccineId')?.value);
  }

  get clinic(): AbstractControl | null {
    return this.vaccination.get('clinic');
  }

  get showGoodFor(): boolean {
    return (this.vaccine?.goodForOptions?.length || 0) > 1;
  }

  get minDate(): Date {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 15);
    return date;
  }

  get fourYears(): Date {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 4);
    return date;
  }

  constructor(private matDialog: MatDialog) {}

  ngOnInit() {
    if (!this.vaccination.get('vaccineId')?.value) {
      this.editing = true;
    }
  }

  confirmDelete() {
    this.matDialog
      .open(ConfirmDialogComponent, {
        data: {
          text: `Delete ${this.vaccine?.name || 'Vaccination'}?`,
          confirmText: 'Delete',
          confirmColor: 'warn',
        },
      })
      .afterClosed()
      .subscribe((shouldContinue) => {
        if (shouldContinue) {
          this.delete.emit();
        }
      });
  }
}
