<form [formGroup]="filterForm">
  <div fxLayout="column" fxHide.gt-xs="true">
    <mat-form-field appearance="standard">
      <mat-label>Search</mat-label>
      <input matInput type="text" [formControl]="searchControl" />
    </mat-form-field>
    <mat-slide-toggle formControlName="viewAll">View All</mat-slide-toggle>
  </div>

  <div
    class="top-10"
    fxLayout="column"
    fxLayout.gt-xs="row wrap"
    fxLayoutAlign.xs="center"
    fxLayoutAlign.gt-xs="start center"
  >
    <div class="toggle-container">
      <mat-slide-toggle formControlName="currentlyFostered">Currently fostered</mat-slide-toggle>
    </div>
    <div class="toggle-container">
      <mat-slide-toggle formControlName="adoptableOnly">Ready for Adoption</mat-slide-toggle>
    </div>
    <div *ngIf="isAdmin" class="toggle-container">
      <mat-slide-toggle formControlName="includeDeceased">Include Deceased</mat-slide-toggle>
    </div>
    <div>
      <mat-form-field>
        <mat-label>Species</mat-label>
        <mat-select formControlName="species" multiple>
          <mat-option *ngFor="let s of species | enumToList" [value]="s.id">{{
            s.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>
