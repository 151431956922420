import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  groups: string[];

  constructor(private router: Router) {}

  returnToLogin(): void {
    this.router.navigate(['/login']).then();
  }
}
