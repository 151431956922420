import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Inject, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { concatMap, delay } from 'rxjs/operators';

import { DocumentType } from 'web/app/models/foster-document.model';
import { environment } from 'web/environments/environment';

import { DocumentUploadStep } from './document-upload-step-enum';

@Component({
  selector: 'app-document-dialog',
  templateUrl: './document-dialog.component.html',
  styleUrls: ['./document-dialog.component.scss'],
})
export class DocumentDialogComponent implements OnDestroy {
  form: FormGroup;
  documentTypes = DocumentType;
  extension: string;
  baseUri: string;
  thumbnail: any;
  scanPreview = true;
  zoomPreview = false;
  previewLoading = false;
  isFileScannable = true;
  scanningMessages = [
    'Flattening image layers...',
    'Converting to black & white...',
    'Polishing up...',
  ];
  scanProgress = 1;
  scanningMessage = 'Uploading your file...';
  editMode = false;
  file: File;
  step = DocumentUploadStep.Upload;
  documentUploadSteps = DocumentUploadStep;
  useScan: boolean;
  private scannableFileTypes = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'svg'];
  private subscription;

  get name() {
    return this.form.get('name');
  }
  get type() {
    return this.form.get('type');
  }
  get spinnerProgress() {
    // progress spinner needs value out of 100
    return this.scanProgress * (100 / (this.scanningMessages.length + 2));
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data,
    private fb: FormBuilder,
    private dialog: MatDialogRef<DocumentDialogComponent>,
    protected http: HttpClient,
    private sanitizer: DomSanitizer,
    private logger: NGXLogger
  ) {
    this.baseUri = environment.apiUri + '/document/preview';

    this.logger.debug('[DOCUMENT_UPLOAD] Opening document upload dialog');
    this.form = this.fb.group({
      id: null,
      name: [null, [Validators.required, this.uniqueNameValidator()]],
      type: [null, Validators.required],
    });
    if (this.data?.document) {
      this.editMode = true;
      this.step = DocumentUploadStep.Info;
      const splitName = this.data.document.name.split('.');
      this.extension = '.' + splitName[splitName.length - 1];
      this.logger.debug(`[DOCUMENT_UPLOAD] Edit mode ${this.data.document.name}`);
      this.form.patchValue({
        id: this.data.document.id,
        type: this.data.document.type,
        name: splitName.slice(0, splitName.length - 1).join('.'),
      });
    }
  }

  toggleZoomView() {
    this.logger.debug(`[DOCUMENT_UPLOAD] Zoom view toggled: ${this.zoomPreview}`);
    this.zoomPreview = !this.zoomPreview;
  }

  upload($fileEvent: { file: File; filePath: string }) {
    const file = $fileEvent.file;
    const name = $fileEvent.filePath;

    this.logger.debug(`[DOCUMENT_UPLOAD] File upload preview initiated: ${name}`);

    this.file = file;

    const splitName = name.split('.');
    this.extension = '.' + splitName[splitName.length - 1];

    this.isFileScannable = false;

    this.form.patchValue({
      name: splitName.slice(0, splitName.length - 1).join('.'),
    });

    if (this.isFileScannable) {
      this.loadPreview(name);
    } else {
      this.step = DocumentUploadStep.Info;
    }
  }

  loadPreview(name: string) {
    this.previewLoading = true;

    this.logger.debug(`[DOCUMENT_UPLOAD] Loading preview for real: ${name}`);

    this.subscription = of(...this.scanningMessages)
      .pipe(concatMap((x) => of(x).pipe(delay(1500))))
      .subscribe((value) => {
        this.scanningMessage = value;
        this.scanProgress++;
        this.logger.debug(
          `[DOCUMENT_UPLOAD] Booping preview loader indicator: ${this.scanningMessage}`
        );
      });

    const formData = new FormData();
    formData.append('file', this.file, name);

    this.logger.debug(`[DOCUMENT_UPLOAD] About to send preview payload via POST: ${name}`);
    this.http
      .post(this.baseUri, formData, {
        params: new HttpParams().set('scan', this.scanPreview.toString()),
        responseType: 'blob',
      })
      .subscribe((response) => {
        this.subscription.unsubscribe();
        this.scanProgress++;
        this.scanningMessage = 'Ready!';
        this.logger.debug(`[DOCUMENT_UPLOAD] Preview response parsing: ${name}`);
        this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(response));
        setTimeout(() => {
          this.previewLoading = false;
          this.step = DocumentUploadStep.Review;
        }, 400);
      });
  }

  uniqueNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!this.data || !this.data.existing) {
        return null;
      }
      if (this.data.existing.find((x) => x.name === control.value + this.extension)) {
        return { uniqueName: { value: control.value } };
      }
      return null;
    };
  }

  accept() {
    this.useScan = true;
    this.step = DocumentUploadStep.Info;
  }

  keepOriginal() {
    this.useScan = false;
    this.step = DocumentUploadStep.Info;
  }

  tryAgain() {
    // @ts-expect-error FIXME
    this.file = null;
    this.thumbnail = null;
    this.form.reset();
    this.scanProgress = 1;
    this.scanningMessage = 'Uploading your file...';
    this.step = DocumentUploadStep.Upload;
  }

  back() {
    if (this.isFileScannable) {
      this.step = DocumentUploadStep.Review;
    } else {
      this.tryAgain();
    }
  }

  save() {
    const formData = this.form.getRawValue();
    formData.name = formData.name + this.extension;
    formData.isScan = this.useScan;
    this.dialog.close({ file: this.file, document: formData });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
