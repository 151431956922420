import { ApplicationRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Select, Store } from '@ngxs/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { concat, interval, Observable, Subject, timer } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import * as semver from 'semver';

import { environment } from 'web/environments/environment';

import { AuthService } from './auth/services/auth.service';
import {
  CheckLoginStatusAction,
  LogoutAction,
  PollLoginStatusAction,
} from './auth/store/auth.actions';
import { AuthState } from './auth/store/auth.state';
import { HotkeysService } from './hotkeys.service';
import { User } from './models/user.model';
import monitoring from './monitoring';

// Init log rocket, sentry, datadog, etc
monitoring();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    public mediaObserver: MediaObserver,
    private authService: AuthService,
    private swUpdate: SwUpdate,
    private snackbar: MatSnackBar,
    private appRef: ApplicationRef,
    private deviceService: DeviceDetectorService,
    private hotKeys: HotkeysService,
    private store: Store
  ) {}
  @Select(AuthState.loggingIn) loading$: Observable<boolean>;
  @Select(AuthState.user) user$: Observable<User>;
  @Select(AuthState.isAdmin) isAdmin$: Observable<boolean>;
  @Select(AuthState.loggedIn) loggedIn$: Observable<boolean>;
  navOpen = true;
  showSidenavOver = false;
  private destroy$ = new Subject<boolean>();
  browserSupported = true;

  focusLostTime = 0;

  @HostListener('window:focus') onFocus() {
    if (this.focusLostTime + 5000 * 60 < Date.now()) {
      window.location.reload();
    }
  }

  @HostListener('document:visibilitychange') onVisibilityChange() {
    if (this.focusLostTime + 1000 * 60 < Date.now()) {
      window.location.reload();
    }
  }

  @HostListener('window:blur') onBlur() {
    this.focusLostTime = Date.now();
  }

  private static isBrowserSupported(deviceInfo): boolean {
    const browser = deviceInfo.browser;
    const version = semver.coerce(deviceInfo.browser_version);

    switch (browser) {
      case 'IE':
        return false; // we did take time to build support. but not planning on hardcore maintaining it
      case 'Chrome':
        return semver.gt(version || '', '29.0.0'); // flexbox support
      default:
        return true;
    }
  }

  ngOnInit(): void {
    const appIsStable = this.appRef.isStable.pipe(first((isStable) => isStable === true));

    if (environment.production && this.swUpdate.isEnabled) {
      let autoActivateUpdate = true;
      const appFreshlyLoadedTimer = timer(2 * 1000);
      appFreshlyLoadedTimer.subscribe(() => (autoActivateUpdate = false));

      // Immediately check for an update
      this.swUpdate.checkForUpdate();

      const updatesAvailable = this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt) => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        }))
      );

      updatesAvailable.subscribe(() => {
        if (autoActivateUpdate) {
          this.swUpdate.activateUpdate().then(() => window.location.reload());
        } else {
          const snack = this.snackbar.open(
            'A new version is available. Click reload to update!',
            'Reload',
            {
              politeness: 'assertive',
              duration: 60000,
            }
          );

          snack.onAction().subscribe(() => {
            this.swUpdate.activateUpdate().then(() => window.location.reload());
          });
        }
      });

      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const everyFiveMinutes = interval(5 * 60 * 1000);
      const everyFiveMinutesOnceAppIsStable = concat(appIsStable, everyFiveMinutes);
      everyFiveMinutesOnceAppIsStable.subscribe(() => this.swUpdate.checkForUpdate());
    }

    this.browserSupported = AppComponent.isBrowserSupported(this.deviceService.getDeviceInfo());
    this.store.dispatch(new CheckLoginStatusAction());

    appIsStable.subscribe(() => {
      this.store.dispatch(new PollLoginStatusAction());
    });

    this.mediaObserver
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((change) => {
        const xs = change.find((x) => x.mqAlias === 'xs');
        const sm = change.find((x) => x.mqAlias === 'sm');
        this.navOpen = !xs && !sm;
        this.showSidenavOver = !!xs || !!sm;
      });

    this.hotKeys.addShortcut({ keys: 'meta.s' }).pipe(takeUntil(this.destroy$)).subscribe();

    this.hotKeys.addShortcut({ keys: 'control.s' }).pipe(takeUntil(this.destroy$)).subscribe();
  }

  signOut(): void {
    this.store.dispatch(new LogoutAction());
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
