<app-foster-list-header
  [filter]="filter$ | async"
  [advancedFilters]="advancedFilters$ | async"
  [search]="search$ | async"
  [total]="total$ | async"
  [isAdmin]="isAdmin$ | async"
  [loading]="loading$ | async"
  (changeSearch)="changeSearch($event)"
  (changeFilter)="changeFilter($event)"
  (changeAdvancedFilters)="changeAdvancedFilters($event)"
  (exportAll)="exportAll()"
  (changeShowFilters)="showFilters = $event"
></app-foster-list-header>
<mat-progress-bar *ngIf="loading$ | async" mode="indeterminate"></mat-progress-bar>

<div class="page-content">
  <div class="read-only-banner" fxLayout="row" fxLayoutAlign="center center">
    <mat-card>
      <mat-card-content fxLayout="column" fxLayoutAlign="center center">
        <h2 class="mat-h2">This app is now read-only</h2>
        <p>Download our new app for the latest features and updates:</p>
        <div fxLayout="row" fxLayoutGap="16px">
          <a
            href="https://apps.apple.com/us/app/rescuebase-animal-rescue/id6504290489"
            target="_blank"
            mat-raised-button
            color="primary"
          >
            <mat-icon>apple</mat-icon>
            App Store
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.rescuebase.app"
            target="_blank"
            mat-raised-button
            color="primary"
          >
            <mat-icon>android</mat-icon>
            Google Play
          </a>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="foster-grid">
    <div
      class="grid-tile add-foster"
      [class.disabled]="showFilters"
      (click)="!showFilters && addFoster()"
    >
      <div class="outline unselectable">
        <h1>Add a Foster</h1>
        <img src="assets/paw.png" />
      </div>
    </div>
    <app-foster-tile
      *ngFor="let foster of fosters$ | async; trackBy: itemHash"
      [disableClick]="showFilters"
      [foster]="foster"
      [showUserName]="showUserName$ | async"
      [canSelect]="false"
      [selectedFosters]="selected$ | async"
      shortPress
      (onShortPress)="handleTileClick(foster)"
    >
    </app-foster-tile>
  </div>

  <div
    fxLayout="row"
    fxLayoutAlign="space-around"
    class="top-20"
    *ngIf="(fosters$ | async)?.length < (total$ | async)"
  >
    <button mat-raised-button color="primary" (click)="loadMore()">Load More</button>
  </div>
</div>
