import { ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { SharedModule } from 'web/app/shared/shared.module';
import { environment } from 'web/environments/environment';

import { OrganizationState } from './organization-store/organization.state';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RbErrorHandler } from './rb-error-handler';
import { RbSnackbar } from './rb-snackbar';
import { FosterService } from './services/foster.service';
import { OrganizationService } from './services/organization.service';
import { PrintService } from './services/print.service';
import { UserService } from './services/user.service';

@NgModule({
  declarations: [PrivacyPolicyComponent],
  imports: [
    NgxsModule.forFeature([OrganizationState]),
    SharedModule,
    RouterModule,
    LoggerModule.forRoot({
      serverLoggingUrl: environment.apiUri + '/log',
      level: environment.production ? NgxLoggerLevel.ERROR : NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.DEBUG,
    }),
  ],
  providers: [RbSnackbar, FosterService, OrganizationService, PrintService, UserService],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: ErrorHandler,
          useClass: RbErrorHandler,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
