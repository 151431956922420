import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SaveStatus } from 'web/app/models/enums';

@Component({
  selector: 'app-save-status',
  templateUrl: './save-status.component.html',
  styleUrls: ['./save-status.component.scss'],
})
export class SaveStatusComponent {
  @Input() status: SaveStatus;
  @Input() updatedBy: string;
  @Input() updatedAt: Date;
  @Output() save = new EventEmitter<void>();
  SaveStatusEnum = SaveStatus;
}
