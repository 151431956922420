import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';

import { AppState } from 'web/app/core/app-state';
import { PaymentMethod } from 'web/app/models/enums';
import { FosterAdoption } from 'web/app/models/foster-adoption.model';
import { User } from 'web/app/models/user.model';
import { RescuebaseValidators } from 'web/app/shared/validators';

@Component({
  selector: 'app-adoption-dialog',
  templateUrl: './adoption-dialog.component.html',
  styleUrls: ['./adoption-dialog.component.scss'],
})
export class AdoptionDialogComponent implements OnDestroy {
  form: FormGroup;
  editMode = false;
  startReturn = false;
  hasReturnDate = false;
  paymentMethod = PaymentMethod;
  private subscription;
  private currentUser: User;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { adoption: FosterAdoption; startReturn: boolean },
    private fb: FormBuilder,
    private store: Store,
    private dialog: MatDialogRef<AdoptionDialogComponent>,
    protected http: HttpClient
  ) {
    this.currentUser = this.store.selectSnapshot((state: AppState) => state.auth.user);
    this.startReturn = this.data.startReturn;
    this.form = this.fb.group(
      this.data.startReturn
        ? {
            id: null,
            returnDate: [new Date(), RescuebaseValidators.validDate],
            returnReason: null,
          }
        : {
            id: null,
            adoptionDate: [new Date(), RescuebaseValidators.validDate],
            adoptedByName: [null, Validators.required],
            adoptedByEmail: [null, Validators.email],
            adoptedName: null,
            returnDate: [null, RescuebaseValidators.validDate],
            returnReason: null,
            paymentMethod: null,
            user: [this.currentUser, Validators.required],
          }
    );
    if (this.data?.adoption) {
      this.form.patchValue(this.data.adoption);
      this.editMode = true;
      this.hasReturnDate = !!this.data.adoption.returnDate;
    }
  }

  save(): void {
    this.dialog.close(this.form.getRawValue());
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
