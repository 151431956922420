import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { AppState } from 'web/app/core/app-state';
import { RbSnackbar } from 'web/app/core/rb-snackbar';
import { FosterService } from 'web/app/core/services/foster.service';
import { OrganizationService } from 'web/app/core/services/organization.service';
import { PrintService } from 'web/app/core/services/print.service';
import { ScannerService } from 'web/app/foster/documents/services/scanner.service';
import { HotkeysService } from 'web/app/hotkeys.service';
import { GAConstants } from 'web/app/models/constants';
import { SaveStatus } from 'web/app/models/enums';
import { FosterAdoption } from 'web/app/models/foster-adoption.model';
import { FosterDocument } from 'web/app/models/foster-document.model';
import { FosterVaccination } from 'web/app/models/foster-vaccination.model';
import { Foster, Species } from 'web/app/models/foster.model';
import { User } from 'web/app/models/user.model';
import { SavePageComponent } from 'web/app/save-page/save-page.component';
import { ConfirmDialogComponent } from 'web/app/shared/components/confirm-dialog/confirm-dialog.component';
import { RescuebaseValidators } from 'web/app/shared/validators';

import { MissingInfoDialogComponent } from './missing-info-dialog/missing-info-dialog.component';

@Component({
  selector: 'app-foster-detail',
  templateUrl: './foster-detail.component.html',
  styleUrls: ['./foster-detail.component.scss'],
})
export class FosterDetailComponent extends SavePageComponent implements OnInit, OnDestroy {
  id: string;
  canScan = false;
  loading = false;
  processing = false;
  foster: Foster;
  form: FormGroup;
  speciesType = Species;
  disabled = true;
  disableUpload = true;
  documents: FosterDocument[] = [];
  loadingDocuments: boolean;
  currentUser: User;
  printing = false;
  saveStatus: SaveStatus;
  adoptions: FosterAdoption[];
  expandPanels: boolean;
  processingVaccinations = false;
  private destroy$ = new Subject<boolean>();

  get imageUri(): string | null {
    return this.foster?.thumbUri ? `${this.fosterService.baseUri}${this.foster.thumbUri}` : null;
  }

  get name(): AbstractControl {
    // @ts-expect-error FIXME
    return this.form.get('name');
  }

  get species(): AbstractControl {
    // @ts-expect-error FIXME
    return this.form.get('species');
  }

  get birthDate(): AbstractControl {
    // @ts-expect-error FIXME
    return this.form.get('birthDate');
  }

  get isOverSixMonths(): boolean {
    if (!this.birthDate?.getRawValue()) return true;
    const date = new Date();
    const sixMonthsAgo = new Date(date.setMonth(date.getMonth() - 6));
    return new Date(this.birthDate.getRawValue()) < sixMonthsAgo;
  }

  get isHeartwormTestingApplicable() {
    return this.species?.getRawValue() === Species.Dog && this.isOverSixMonths;
  }

  get user(): AbstractControl | null {
    return this.form.get('user');
  }

  get vaccinations(): FormArray {
    return this.form.get('vaccinations') as FormArray;
  }

  sortVaccinations(vaccinations) {
    if (!vaccinations) return;
    vaccinations.sort((a, b) => {
      if (a.givenDate && b.givenDate) {
        if (a.givenDate > b.givenDate) return 1;
        else if (a.givenDate < b.givenDate) return -1;
      }

      if (!a.dueDate || !b.dueDate) return 0;

      if (a.dueDate < b.dueDate) return -1;
      else if (a.dueDate > b.dueDate) return 1;

      return 0;
    });
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fosterService: FosterService,
    private organizationService: OrganizationService,
    private fb: FormBuilder,
    private printService: PrintService,
    private matDialog: MatDialog,
    private snackbar: RbSnackbar,
    private store: Store,
    protected gaService: GoogleAnalyticsService,
    protected hotKeys: HotkeysService,
    private breakpointObserver: BreakpointObserver,
    private scannerService: ScannerService
  ) {
    super(hotKeys);

    this.currentUser = this.store.selectSnapshot((state: AppState) => state.auth.user);

    this.adoptions = [];

    this.form = this.fb.group({
      id: null,
      user: [this.currentUser, RescuebaseValidators.requiresId],
      name: ['', Validators.required],
      species: null,
      gender: null,
      breed: null,
      birthDate: [null, RescuebaseValidators.validDate],

      heartwormTestingCompletedDate: [null, RescuebaseValidators.validDate],
      heartwormPositive: null,
      comboTestingCompletedDate: [null, RescuebaseValidators.validDate],
      fivPositive: null,
      felvPositive: null,
      nextHeartwormMedDue: [null, RescuebaseValidators.validDate],
      nextFleaTickMedDue: [null, RescuebaseValidators.validDate],
      nextVaccinesDue: [null, RescuebaseValidators.validDate],
      fixedOnDate: [null, RescuebaseValidators.validDate],
      fixedBeforeIntake: null,
      microchipId: null,
      positiveFecalTestDate: [null, RescuebaseValidators.validDate],
      clearFecalTestDate: [null, RescuebaseValidators.validDate],
      fecalFollowUpDate: [null, RescuebaseValidators.validDate],
      vetId: null,
      readyForAdoption: false,
      history: null,
      notes: null,
      privateNotes: null,
      intakeDate: [null, RescuebaseValidators.validDate],
      currentFood: null,
      training: null,
      deceasedDate: [null, RescuebaseValidators.validDate],
      isAdopted: false,
      isInHospice: false,
      vaccinations: this.fb.array([]),
      weight: null,
    });

    // detect screen size changes
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: BreakpointState) => {
        this.expandPanels = !result.matches;
      });
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Disable the entire form
    this.form.disable();

    this.route.params.subscribe((params) => {
      this.id = params.id;
      if (this.id) {
        this.loadFoster();
        this.loadDocuments();
      }
    });
  }

  startAutoSave(skipCount = 0): void {
    // Disable auto-save since the form is read-only
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    super.ngOnDestroy();
  }

  private buildVaccinations(vaccinations: FosterVaccination[]): void {
    // If vaccinations were cleared, clear the whole form
    if (this.vaccinations.controls.length && !vaccinations?.length) {
      this.vaccinations.clear({ emitEvent: false });
    }

    // Add and update vaccinations
    vaccinations?.forEach((x) => {
      const formControl = this.vaccinations.controls.find((y) => y.get('id')?.value === x.id);
      if (formControl) {
        if (!formControl?.dirty) {
          formControl.patchValue(x, { emitEvent: false });
        }
      } else {
        this.vaccinations.push(
          this.fb.group({
            id: x.id,
            vaccineId: x.vaccineId,
            name: x.name,
            dueDate: [x.dueDate, RescuebaseValidators.validDate],
            givenDate: [x.givenDate, RescuebaseValidators.validDate],
            clinic: x.clinic,
            goodForYearCount: x.goodForYearCount,
          }),
          { emitEvent: false }
        );
      }
    });
  }

  private loadFoster(isReload?: boolean) {
    if (!isReload) this.loading = true;

    this.fosterService.get(this.id).subscribe(
      (foster) => {
        this.foster = foster;
        this.form.patchValue(foster);
        this.saveStatus = SaveStatus.UpToDate;
        this.buildVaccinations(foster.vaccinations);
        this.form.disable(); // Ensure form stays disabled after data load
        this.disabled = true;
        this.disableUpload = true;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );

    this.fosterService.getAdoptions(this.id).subscribe((adoptions) => {
      this.adoptions = adoptions;
    });
  }

  private enableDisableByAuthorization() {
    this.disabled = true;
    this.disableUpload = true;
  }

  private loadDocuments() {
    this.loadingDocuments = true;
    this.fosterService.getDocuments(this.id).subscribe(
      (documents) => {
        this.documents = documents;
        this.loadingDocuments = false;
      },
      () => (this.loadingDocuments = false)
    );
  }

  async save(): Promise<void> {
    const foster = this.form.getRawValue();
    if (this.form.invalid) return;

    if (!foster.isAdopted && foster.adoptionDate) foster.isAdopted = true;

    this.setSaveStatus(SaveStatus.Saving);
    this.fosterService.save(foster).subscribe(
      (res) => {
        if (!this.id) {
          this.gaService.event(
            GAConstants.fosterDetail.save.action,
            GAConstants.fosterDetail.category,
            GAConstants.fosterDetail.save.label.new
          );
          window.history.replaceState({}, '', '/foster/' + res.id);
          this.id = res.id;
          this.form.patchValue({ id: res.id });
        } else {
          this.gaService.event(
            GAConstants.fosterDetail.save.action,
            GAConstants.fosterDetail.category,
            GAConstants.fosterDetail.save.label.existing
          );
        }
        this.foster = res;
        this.buildVaccinations(res.vaccinations);
        this.form.markAsPristine();
        this.setSaveStatus(SaveStatus.Saved);
      },
      () => {
        this.setSaveStatus(SaveStatus.Unsaved);
      }
    );
  }

  private setSaveStatus(status: SaveStatus) {
    this.saveStatus = status;

    if (status === SaveStatus.Saving) this.processing = true;
    else this.processing = false;
  }

  copy(): void {
    const foster = this.form.getRawValue();
    this.foster = FosterService.copy(foster);
    this.form.reset(this.foster);
    // @ts-expect-error FIXME
    this.id = null;
    this.adoptions = [];
    window.history.replaceState({}, '', '/foster/add');
    this.gaService.event(GAConstants.fosterDetail.copy.action, GAConstants.fosterDetail.category);
  }

  delete(): void {
    this.matDialog
      .open(ConfirmDialogComponent, {
        data: {
          text: `Are you sure you want to delete ${this.foster.name}? This can't be undone.`,
          confirmText: 'Delete',
          confirmColor: 'warn',
        },
      })
      .afterClosed()
      .subscribe((shouldContinue) => {
        if (shouldContinue) {
          this.fosterService
            .delete(this.foster)
            .subscribe(() => this.router.navigate(['/foster/list']).then());
        }
      });
  }

  openMissingInfoDialog(missingInfoItems): void {
    this.matDialog
      .open(MissingInfoDialogComponent, {
        data: { ...missingInfoItems },
      })
      .afterClosed()
      .subscribe((shouldContinue) => {
        if (shouldContinue) this.printCoverSheet();
      });
  }

  getMissingInfoItems() {
    const { species, comboTestingCompletedDate, heartwormTestingCompletedDate, vaccinations } =
      this.form.getRawValue();
    return {
      comboTest: species === Species.Cat && !comboTestingCompletedDate,
      heartwormTest: !heartwormTestingCompletedDate && this.isHeartwormTestingApplicable,
      vaccinations: !vaccinations.some((x) => !!x.givenDate),
    };
  }

  tryPrintCoverSheet(): void {
    const items = this.getMissingInfoItems();
    if (items.comboTest || items.heartwormTest || items.vaccinations) {
      this.openMissingInfoDialog(items);
    } else {
      this.printCoverSheet();
    }
  }

  printCoverSheet(): void {
    const filename = `${this.foster.name}'s Cover Sheet.pdf`;

    if (this.printService.canOpenDocumentInNewTab()) {
      window.open(this.fosterService.getCoverSheetUrl(this.foster, filename), '_blank');
    } else {
      this.snackbar.wait('Generating pdf...');
      this.printing = true;

      this.fosterService.getCoverSheet(this.foster, filename).subscribe((pdf) => {
        this.printService
          .printPdf(pdf, filename)
          .then(() => {
            this.snackbar.dismiss();
            this.printing = false;
          })
          .catch((e) => {
            this.snackbar.error(`Error downloading PDF: ${JSON.stringify(e)}`);
          });
      });
    }

    this.gaService.event(GAConstants.fosterDetail.print.action, GAConstants.fosterDetail.category);
  }

  printAdoptionContract(): void {
    if (this.printService.canOpenDocumentInNewTab()) {
      window.open(this.organizationService.getAdoptionContractUrl(), '_blank');
    } else {
      this.snackbar.wait('Getting pdf...');
      this.printing = true;

      this.organizationService.getAdoptionContract().subscribe((pdf) => {
        this.printService
          .printPdf(pdf, 'Adoption Contract.pdf')
          .then(() => {
            this.snackbar.dismiss();
            this.printing = false;
          })
          .catch((e) => {
            this.snackbar.error(`Error downloading PDF: ${JSON.stringify(e)}`);
          });
      });
    }

    this.gaService.event(GAConstants.fosterDetail.print.action, GAConstants.fosterDetail.category);
  }

  markDeceased(): void {
    this.foster.deceasedDate = new Date();
    this.form.patchValue(this.foster);
    void this.save();
  }

  uploadImage(fileEvent: { file: File; filePath: string }): void {
    this.fosterService
      .uploadImage(this.foster.id, fileEvent.file, fileEvent.filePath)
      .subscribe((response) => {
        this.foster.thumbUri = response.thumbUri;
      });
  }

  // ####### VACCINATIONS #######

  createVaccination() {
    this.vaccinations.push(
      this.fb.group({
        id: uuidv4(),
        vaccineId: null,
        name: null,
        dueDate: [null, RescuebaseValidators.validDate],
        givenDate: [null, RescuebaseValidators.validDate],
        clinic: null,
        goodForYearCount: null,
      }),
      { emitEvent: false }
    );
  }

  processVaccinations() {
    const showUpdatedSnackbar = this.vaccinations?.controls?.length;
    this.processingVaccinations = true;
    this.form.disable({ emitEvent: false });
    this.disabled = true;
    this.fosterService.processVaccinations(this.foster.id).subscribe((res) => {
      this.buildVaccinations(res.vaccinations);
      if (showUpdatedSnackbar) this.snackbar.success('Vaccination schedule up to date!');

      this.processingVaccinations = false;
      this.enableDisableByAuthorization();
    });
  }

  deleteVaccination(vaccinationIndex: number) {
    this.vaccinations.removeAt(vaccinationIndex);
  }

  // ####### ADOPTIONS #######

  deleteAdoption(adoption: FosterAdoption): void {
    if (!adoption.id || !this.currentUser?.isAdmin) return;

    this.fosterService.deleteAdoption(this.foster.id, adoption.id).subscribe(() => {
      this.adoptions = this.adoptions.filter((a) => a.id !== adoption.id);
    });
  }

  getAdoptions(): Subscription {
    return this.fosterService.getAdoptions(this.foster.id).subscribe((adoptions) => {
      this.adoptions = adoptions;
      this.loadFoster(true);
    });
  }

  saveAdoption(adoption: FosterAdoption): void {
    this.setSaveStatus(SaveStatus.Saving);

    this.fosterService.saveAdoption(this.foster.id, adoption).subscribe(
      () => {
        this.getAdoptions().add(() => {
          this.setSaveStatus(SaveStatus.Saved);
        });
      },
      () => {
        this.setSaveStatus(SaveStatus.Unsaved);
      }
    );
  }

  // ####### DOCUMENTS #######

  createDocument(fileEvent: { file: File; document: FosterDocument }): void {
    // Disabled in read-only mode
  }

  deleteDocument(documentId: string): void {
    // Disabled in read-only mode
  }

  saveDocument(doc: FosterDocument): void {
    // Disabled in read-only mode
  }

  downloadFile(id: string): void {
    this.fosterService.getDocument(id).subscribe((response) => (window.location = response.url));
  }
}
