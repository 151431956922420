import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToList',
})
@Injectable()
export class EnumToListPipe implements PipeTransform {
  transform(enumType) {
    if (!enumType) {
      return null;
    }

    return Object?.keys(enumType)
      ?.map((k) => enumType[k])
      ?.map((v) => (typeof v === 'string' ? { id: enumType[v], name: v.replace(/_/g, ' ') } : null))
      ?.filter((x) => x !== null);
  }
}
