import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/browser';
import * as LogRocket from 'logrocket';

import { environment } from 'web/environments/environment';

function init() {
  try {
    if (!environment.local) {
      LogRocket.init('2xnllj/rescuebase');
      Sentry.init({
        dsn: 'https://583ff80e539c4e7aaf968001f065bb8c@o277704.ingest.us.sentry.io/1498035',
        integrations: [Sentry.browserTracingIntegration()],
        release: environment.version,
        tracesSampleRate: 0.05,
      });

      // Embed LogRocket URL into Sentry
      LogRocket.getSessionURL((sessionURL) => {
        Sentry.setTag('sessionURL', sessionURL);
      });

      datadogRum.init({
        clientToken: 'pub392af7d0562c0f21bb7e812fe4cf5277',
        applicationId: '48d30b06-c161-428c-8568-3b16c3682d0d',
        site: 'datadoghq.com',
        service: 'rescuebase',
        proxy: `${window.origin}/api/telemetry`,

        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 5,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });

      datadogRum.startSessionReplayRecording();

      datadogLogs.init({
        clientToken: 'pub392af7d0562c0f21bb7e812fe4cf5277',
        site: 'datadoghq.com',
        forwardConsoleLogs: 'all',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export default init;
