import { versions } from './versions';

export const environment = {
  local: false,
  production: true,
  apiUri: '/api',
  facebookId: '347193752541477',
  googleAnalyticsTag: 'UA-11053457-11',
  version: versions.version,
};
