export class CheckLoginStatusAction {
  static readonly type = '[Auth] Check Login Status';
}

export class LoginSuccessAction {
  static readonly type = '[Auth] Login Success';
}

export class PollLoginStatusAction {
  static readonly type = '[Auth] Poll Login Status';
}

export class LogoutAction {
  static readonly type = '[Auth] Logout';
}

export class LoggingIn {
  static readonly type = '[Auth] Logging In';
}
