import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { NGXLogger } from 'ngx-logger';
import { throwError } from 'rxjs';

import { RbSnackbar } from 'web/app/core/rb-snackbar';

@Injectable()
export abstract class BaseService {
  baseUri: string;

  protected constructor(
    protected http: HttpClient,
    protected snackbar: RbSnackbar,
    protected logger: NGXLogger
  ) {}

  handleError = (response: HttpErrorResponse, errorMessage?: string) => {
    this.logger.error('Base service error', response);

    const message = response.error?.message;

    // Note: 401's are handled by auth-interceptor and redirect the user to the login page
    if (!!response && !isNaN(response.status)) {
      if (response.status === 400) {
        this.snackbar.error(message);
        return throwError({ isHandled: true }); // need to throw error so that success callback isn't fired
      } else if (response.status === 401) {
        return throwError({ isHandled: true }); // need to throw error so that success callback isn't fired
      } else if (response.status === 403) {
        // Server error 403 Forbidden means user doesn't have permission to hit the API endpoint
        this.snackbar.error(
          'An attempt was made to access data you do not have permission to access.'
        );
        // do nothing and let error bubble up
      } else if (response.status === 404) {
        this.snackbar.error(
          `The data request was not found. [${response.status} ${response.statusText}]`
        );
        return throwError({ isHandled: true }); // need to throw error so that success callback isn't fired
      } else if (response.status === 429) {
        this.snackbar.error("You've made too many requests. Try again in a few minutes");
        return throwError({ isHandled: true }); // need to throw error so that success callback isn't fired
      } else if (response.status >= 500 || response.status === 0) {
        if (errorMessage) {
          this.snackbar.error(errorMessage);
        } else if (message) {
          this.snackbar.error(`${message}`);
        } else {
          this.snackbar.error('Connection error - server unavailable.');
        }
        return throwError({ isHandled: true }); // need to throw error so that success callback isn't fired
      }
    } else if (errorMessage) {
      Sentry.captureException(response);
      this.snackbar.error(errorMessage);
      return throwError({ isHandled: true });
    }

    // Let everything else bubble up
    return throwError(response);
  };
}
