import { AbstractControl, ValidationErrors } from '@angular/forms';
import moment from 'moment';

export class RescuebaseValidators {
  static requiresId(control: AbstractControl): ValidationErrors | null {
    if (!control.value || !control.value.id) {
      return { required: true };
    }
    return null;
  }

  static validDate(control: AbstractControl): ValidationErrors | null {
    if (
      control?.value &&
      (!moment(control.value).isValid() || moment(control.value) > moment().add(100, 'years'))
    ) {
      return { validDate: 'Please enter a valid date' };
    }
    return null;
  }
}
