<input
  type="file"
  #file
  class="file-button"
  (change)="onFileSelected()"
  multiple
  accept="image/*"
/>
<ngx-file-drop
  (onFileDrop)="dropped($event)"
  [disabled]="disabled"
  [class.disabled]="disabled"
  (click)="!disabled && selectPhoto()"
  [dropZoneClassName]="'thumbnail-upload'"
  [contentClassName]="'content'"
  accept="image/*"
  [ngClass.xs]="
    imageUri ? 'upload-flex-height' : fosterId ? 'upload-short-height' : 'upload-no-height'
  "
  [ngClass.sm]="
    imageUri ? 'upload-flex-height' : fosterId ? 'upload-short-height' : 'upload-no-height'
  "
>
  <ng-template ngx-file-drop-content-tmp>
    <div class="image-container">
      <div class="foster-photo">
        <img
          *ngIf="imageUri"
          [class.disabled]="disabled"
          class="foster-photo"
          alt="Foster Image"
          [src]="imageUri"
          (loaded)="imageLoaded = $event"
        />
      </div>
      <div *ngIf="fosterId" class="photo-upload-text">Upload a Photo</div>
      <div class="photo-upload-text">
        <span *ngIf="fosterId && imageLoaded">Upload a Photo</span>
        <span fxHide.xs="true" *ngIf="!fosterId">Add a name first,<br />then upload a photo!</span>
      </div>
    </div>
  </ng-template>
</ngx-file-drop>
