import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-missing-info-dialog',
  templateUrl: './missing-info-dialog.component.html',
  styleUrls: ['./missing-info-dialog.component.scss'],
})
export class MissingInfoDialogComponent {
  constructor(
    private dialog: MatDialogRef<MissingInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { comboTest: boolean; heartwormTest: boolean; vaccinations: boolean }
  ) {}

  close(shouldContinue?: boolean) {
    this.dialog.close(shouldContinue);
  }
}
