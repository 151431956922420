import { Cacheable } from './cacheable';
import { Roles } from './constants';

export class User implements Cacheable {
  id: string;
  facebookId?: string;
  email: string;
  firstName: string;
  lastName: string;
  photoUrl?: string;

  organization: UserOrganization;
  organizations: UserOrganization[];
  roles: string[];

  constructor(options) {
    this.id = options.id;
    this.facebookId = options.facebookId;
    this.email = options.email;
    this.firstName = options.firstName;
    this.lastName = options.lastName;
    this.photoUrl = options.photoUrl;
    this.roles = options.roles || [];
  }

  get isAdmin(): boolean {
    return this.getRole(Roles.Admin);
  }

  set isAdmin(state) {
    this.setRole(Roles.Admin, state);
  }

  get isAdoptionCoordinator(): boolean {
    return this.getRole(Roles.AdoptionCoordinator);
  }

  set isAdoptionCoordinator(state: boolean) {
    this.setRole(Roles.AdoptionCoordinator, state);
  }

  static getCached() {
    try {
      return new User(JSON.parse(localStorage.getItem('currentUser') || ''));
    } catch (e) {
      return {} as User;
    }
  }

  static hasRole(user: User, roles: string[]) {
    return !!(user.roles && roles.some((role) => user.roles.includes(role)));
  }

  cache() {
    localStorage.setItem('currentUser', JSON.stringify(this));
  }

  setRole(roleName, state) {
    if (state) {
      if (!this.roles.find((role) => role === roleName)) {
        this.roles.push(roleName);
      }
    } else {
      this.roles = this.roles.filter((role) => role !== roleName);
    }
  }

  getRole(roleName) {
    return this.roles?.includes(roleName);
  }
}

class UserOrganization {
  id: string;
  isAdmin: boolean;
}
