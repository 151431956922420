import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { Filter, FosterFilter } from 'web/app/models/foster-filter.model';
import { Species } from 'web/app/models/foster.model';

@Component({
  selector: 'app-simple-filter',
  templateUrl: './simple-filter.component.html',
  styleUrls: ['./simple-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleFilterComponent implements OnDestroy, OnChanges {
  @Input() isAdmin: boolean;
  @Input() filter: FosterFilter | null;
  @Input() filterForm: FormGroup;
  @Input() search: string;
  @Output() changeFilter = new EventEmitter<Filter[]>();
  @Output() changeSearch = new EventEmitter<string>();

  searchControl = new FormControl();
  species = Species;

  private destroy$ = new Subject<boolean>();

  constructor() {
    this.searchControl.valueChanges
      .pipe(debounceTime(150), takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value !== this.search) {
          this.changeSearch.emit(value);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.search) {
      this.searchControl.patchValue(this.search);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
