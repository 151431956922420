import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({ selector: '[shortPress]' })
export class ShortPressDirective {
  @Input() duration = 250;

  @Output()
  onShortPress = new EventEmitter();

  private _timeout: any;
  private _isShort: boolean;

  @HostListener('touchstart')
  @HostListener('mousedown')
  onMouseDown(event) {
    this._isShort = true;
    this._timeout = setTimeout(() => {
      this._isShort = false;
    }, this.duration);

    return event;
  }

  @HostListener('touchend', ['$event'])
  @HostListener('mouseup', ['$event'])
  onMouseUp(event) {
    // Allowing the event to continue will yeild a `mouseup` event which causes errant clicks
    if (event.type === 'touchend' && event.cancelable) {
      event.preventDefault();
    }
    if (this._isShort) {
      this.onShortPress.emit(event);
    }
    clearTimeout(this._timeout);
  }

  @HostListener('touchcancel')
  @HostListener('touchmove')
  @HostListener('mouseleave')
  onMouseLeave() {
    this._isShort = false;
    clearTimeout(this._timeout);
  }
}
