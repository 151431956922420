import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as tf from '@tensorflow/tfjs';
import { NGXLogger } from 'ngx-logger';
import { InferenceSession } from 'onnxruntime-web';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'web/environments/environment';

import { initOnnxSession } from './worker/onnx';
import {
  runModelInference,
  ScanDataPrepare,
  ScanDataProcess,
  ScanMessageType,
} from './worker/scanner';
import { PromiseWorker, WorkerState } from './worker/worker';

const CACHE_VERSION = 1;
const CURRENT_CACHES = {
  model: 'model-v' + CACHE_VERSION,
};

const MODEL_URL = 'https://files.rescuebase.io/ml_document_scanner.onnx';

@Injectable({
  providedIn: 'root',
})
export class ScannerService {
  private scanWorker?: PromiseWorker;
  private onnxSession: InferenceSession;
  downloadState = new BehaviorSubject<WorkerState>(WorkerState.Loading);

  constructor(protected logger: NGXLogger, private snackbar: MatSnackBar) {}

  async init(): Promise<void> {
    this.createNewWorker();

    const model = await this.fetchModel();
    this.downloadState.next(WorkerState.Ready);

    this.scanWorker?.workerState.subscribe(async (state) => {
      this.onnxSession = await initOnnxSession(model);

      if (state === WorkerState.Ready && environment.local) {
        this.snackbar.open('scanner worker ready', 'DISMISS', {
          politeness: 'polite',
          duration: 10000,
          panelClass: 'snack-bar-success',
        });
      }
    });
  }

  private createNewWorker() {
    // If we don't instantiate the worker inside this block, inside an angular entity, it will fail. I don't know why.
    // So don't bother trying to pass a path or url to `PromiseWorker` and instantiate it there.
    if (Worker && typeof Worker !== 'undefined') {
      this.scanWorker = new PromiseWorker(
        new Worker(new URL('./worker/scanner.worker', import.meta.url))
      );
    } else {
      this.logger.error('Web Workers are not supported in this environment.');
    }
  }

  private async convertFileToImageData(file: File): Promise<ImageData | undefined> {
    // Paint the file to a canvas so we can extract an ImageData representation of it.
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = URL.createObjectURL(file);
    await new Promise((resolve) => (img.onload = resolve));

    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(img, 0, 0);

    return ctx?.getImageData(0, 0, img.width, img.height);
  }

  scan(file: File): Promise<Blob> {
    return new Promise((resolve: (value: Blob) => void) => {
      // @ts-expect-error FIXME
      this.scanWorker.workerState.subscribe(async (state) => {
        if (state === WorkerState.Ready) {
          // @ts-expect-error FIXME
          const preparedScanData = await this.scanWorker.sendMessage<ScanDataPrepare>({
            type: ScanMessageType.Prepare,
            // @ts-expect-error FIXME
            image: await this.convertFileToImageData(file),
          });

          const tensor = tf.tensor(preparedScanData.tensor.data, preparedScanData.tensor.shape);

          const modelOutput = await runModelInference(tensor, this.onnxSession);

          tf.dispose(tensor);

          // @ts-expect-error FIXME
          const out = await this.scanWorker.sendMessage<ScanDataProcess>({
            type: ScanMessageType.Process,
            original: preparedScanData.original,
            tensor: modelOutput,
          });

          const canvas = document.createElement('canvas');
          canvas.width = out.imageData.width;
          canvas.height = out.imageData.height;
          const ctx = canvas.getContext('2d');
          // @ts-expect-error FIXME
          ctx.putImageData(out.imageData, 0, 0);

          canvas.toBlob((blob) => {
            // @ts-expect-error FIXME
            resolve(blob);
          });

          // After a scan we replace the worker with a new one to free up memory.
          this.scanWorker?.terminate();
          this.createNewWorker();
        }
      });
    });
  }

  async fetchModel() {
    // First, check the cache
    const cache = await caches.open(CURRENT_CACHES.model);
    let response = await cache.match(MODEL_URL);

    // If we don't have a response, fetch it from the network
    if (!response) {
      response = await fetch(MODEL_URL);

      // If we get a response, cache it
      if (response.ok) {
        await cache.put(MODEL_URL, response.clone());
      }
    }

    return response.arrayBuffer();
  }
}
