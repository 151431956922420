import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-fecal-followup-dialog',
  templateUrl: './fecal-followup-dialog.component.html',
  styleUrls: ['./fecal-followup-dialog.component.scss'],
})
export class FecalFollowupDialogComponent {
  lastDosageDate: number;

  constructor(private dialog: MatDialogRef<FecalFollowupDialogComponent>) {}

  close() {
    this.dialog.close(this.lastDosageDate);
  }
}
