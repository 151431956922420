import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

import { RbSnackbar } from 'web/app/core/rb-snackbar';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageUploadComponent {
  @Input() fosterId: string;
  @Input() imageUri: string;
  @Input() disabled: boolean;

  @Output() upload = new EventEmitter<any>();

  @ViewChild('file') file;

  imageLoaded = false;
  private validFileTypes = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'svg'];
  private maxFileSize = 20000000; // 20Mb

  constructor(private snackbar: RbSnackbar) {}

  selectPhoto() {
    this.file.nativeElement.click();
  }

  onFileSelected() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (const key in files) {
      if (!isNaN(Number(key))) {
        if (!this.isValidFileType(files[key].name)) {
          return;
        }
        if (this.isOverMaxFileSize(files[key].size)) {
          return;
        }
        this.upload.emit({ file: files[key], filePath: key });
      }
    }
  }

  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (!droppedFile.fileEntry.isFile) {
        continue;
      }
      if (!this.isValidFileType(droppedFile.relativePath)) {
        return;
      }

      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        if (this.isOverMaxFileSize(file.size)) {
          return;
        }
        this.upload.emit({ file, filePath: droppedFile.relativePath });
      });
    }
  }

  private isOverMaxFileSize(size: number) {
    if (size > this.maxFileSize) {
      this.snackbar.error('Please choose a file less than 20Mb');
      return true;
    }
    return false;
  }

  private isValidFileType(fileName: string) {
    if (!fileName) {
      this.snackbar.error('Please upload a valid file type: ' + this.validFileTypes.join(', '));
      return false;
    }
    const splitFileName = fileName.split('.');
    const fileType = splitFileName[splitFileName.length - 1].toLowerCase();
    if (this.validFileTypes.indexOf(fileType) === -1) {
      this.snackbar.error('Please upload a valid file type: ' + this.validFileTypes.join(', '));
      return false;
    }
    return true;
  }
}
