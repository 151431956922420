export enum SaveStatus {
  Unsaved = 0,
  UpToDate = 1,
  Saving = 2,
  Saved = 3,
}

export enum PaymentMethod {
  Cash = 1,
  Website = 2,
  PayPal = 3,
  Venmo = 4,
  Other = 5,
}
