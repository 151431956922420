import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumName',
})
@Injectable()
export class EnumNamePipe implements PipeTransform {
  transform(val, enumType) {
    if (
      val === undefined ||
      val === null ||
      enumType === undefined ||
      enumType[val] === undefined
    ) {
      return val;
    }
    return enumType[val].replace(/_/g, ' ');
  }
}
