import { Species } from './foster.model';
import { Vaccine } from './vaccine.model';

export class Organization {
  id: string;
  name: string;
  vaccines: Vaccine[];
  medicalInfos: MedicalInfo[];
}

export class MedicalInfo {
  id: string;
  species: Species;
  minAge: number; // weeks
  text: string;
  type: MedicalInfoType;
}

export enum MedicalInfoType {
  Fecal = 0,
  Prevention = 1,
  SpayAndNeuter = 2,
  Vaccination = 3,
}
