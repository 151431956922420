import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class RbSnackbar {
  private DURATION_FOREVER = 0;
  private durationTemporary = 4000;
  private CLOSE_TEXT = 'DISMISS';

  constructor(private snackbar: MatSnackBar) {}

  wait(message: string) {
    return this.snackbar.open(message, this.CLOSE_TEXT, {
      politeness: 'polite',
      verticalPosition: 'bottom',
      duration: this.DURATION_FOREVER,
    });
  }

  success(message: string, duration = 4000) {
    return this.snackbar.open(message, this.CLOSE_TEXT, {
      politeness: 'polite',
      verticalPosition: 'bottom',
      duration,
    });
  }

  info(message: string) {
    return this.success(message);
  }

  error(message: string, duration = 0) {
    return this.snackbar.open(message, this.CLOSE_TEXT, {
      politeness: 'assertive',
      verticalPosition: 'bottom',
      duration: duration ? duration : this.DURATION_FOREVER,
      panelClass: 'snack-bar-error',
    });
  }

  dismiss() {
    this.snackbar.dismiss();
  }
}
