export const GAConstants = {
  fosterDetail: {
    category: 'foster_detail',
    save: {
      label: {
        new: 'new',
        existing: 'existing',
      },
      action: 'save',
    },
    print: {
      action: 'print',
    },
    copy: {
      action: 'copy',
    },
  },
  fosterList: {
    category: 'foster_list',
    viewAll: {
      label: {
        on: 'on',
        off: 'off',
      },
      action: 'view_all_toggle',
    },
  },
};

export const Roles = {
  Admin: 'admin',
  AdoptionCoordinator: 'adoptionCoordinator',
};
