import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import { RbSnackbar } from 'web/app/core/rb-snackbar';
import { Gender, Species } from 'web/app/models/foster.model';
import { User } from 'web/app/models/user.model';

@Component({
  selector: 'app-base-foster-info',
  templateUrl: './base-foster-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseFosterInfoComponent {
  @Input() form: FormGroup;
  @Input() currentUser: User;
  @Input() weightDate: Date;

  speciesType = Species;
  gender = Gender;

  get name(): AbstractControl | null {
    return this.form?.get('name');
  }

  get species(): AbstractControl | null {
    return this.form?.get('species');
  }

  get user(): AbstractControl | null {
    return this.form?.get('user');
  }

  get showWeightDate(): boolean {
    return !!this.form?.get('weight')?.value && !!this.weightDate;
  }

  constructor(private snackbar: RbSnackbar) {}

  notifyCopied(): void {
    this.snackbar.success('Email copied!');
  }
}
