import { ChangeDetectionStrategy, Component, Input, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrganizationState } from 'web/app/core/organization-store/organization.state';
import { FecalFollowupDialogComponent } from 'web/app/foster/foster-detail/fecal-followup-dialog/fecal-followup-dialog.component';
import { Species } from 'web/app/models/foster.model';

@Component({
  selector: 'app-medical-info',
  templateUrl: './medical-info.component.html',
  styleUrls: ['./medical-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicalInfoComponent {
  @Input() form: FormGroup;
  @Input() species: Species;
  @Input() birthDate: Date;
  @Input() hideMicrochip: boolean;

  speciesType = Species;
  fecalInfo$: Observable<string>;
  preventionInfo$: Observable<string>;
  spayNeuterInfo$: Observable<string>;

  get positiveFecalTestDate(): AbstractControl | null {
    return this.form.get('positiveFecalTestDate');
  }

  get fecalFollowUpDate(): AbstractControl | null {
    return this.form.get('fecalFollowUpDate');
  }

  get isOverSixMonths(): boolean {
    if (!this.birthDate) return true;
    const date = new Date();
    const sixMonthsAgo = new Date(date.setMonth(date.getMonth() - 6));
    return new Date(this.birthDate) < sixMonthsAgo;
  }

  constructor(private store: Store, private matDialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.species || changes.birthDate) {
      this.fecalInfo$ = this.store
        .select(OrganizationState.fecalInfo)
        .pipe(map((fn) => fn(this.species, this.birthDate)));

      this.preventionInfo$ = this.store
        .select(OrganizationState.preventionInfo)
        .pipe(map((fn) => fn(this.species, this.birthDate)));

      this.spayNeuterInfo$ = this.store
        .select(OrganizationState.spayNeuterInfo)
        .pipe(map((fn) => fn(this.species, this.birthDate)));
    }
  }

  fecalTestDateChanged(): void {
    if (!this.positiveFecalTestDate?.value) {
      return;
    }
    this.matDialog
      .open(FecalFollowupDialogComponent, {
        minWidth: '300px',
      })
      .afterClosed()
      .subscribe((lastDosageDate) => {
        if (lastDosageDate) {
          const followUpDate = new Date(lastDosageDate);
          followUpDate.setDate(followUpDate.getDate() + 14);
          this.fecalFollowUpDate?.setValue(followUpDate);
        }
      });
  }
}
