import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { RbSnackbar } from 'web/app/core/rb-snackbar';
import { User } from 'web/app/models/user.model';
import { environment } from 'web/environments/environment';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  users: User[];

  constructor(
    protected http: HttpClient,
    protected snackbar: RbSnackbar,
    protected logger: NGXLogger,
    private router: Router
  ) {
    super(http, snackbar, logger);
    this.baseUri = environment.apiUri + '/user';
  }

  getAll(): Observable<User[]> {
    if (this.users) {
      return of(this.users);
    }
    return this.http.get(this.baseUri).pipe(
      map((response: User[]) => {
        this.users = response;
        return response;
      }),
      catchError((error) => this.handleError(error, 'Error loading volunteer list'))
    );
  }

  get(id: string): Observable<User> {
    if (this.users) {
      const user = this.users.find((x) => x.id === id);
      if (user) {
        return of(new User(user));
      }
    }
    return this.http.get(this.baseUri + '/' + id).pipe(
      map((response: User) => {
        return new User(response);
      }),
      catchError((error) => this.handleError(error, 'Error loading user info'))
    );
  }

  save(user: User): Observable<User> {
    this.snackbar.wait('Saving...');
    // @ts-expect-error FIXME
    this.users = null;
    if (user.id) {
      return this.put(user);
    }
    return this.post(user);
  }

  put(user: User): Observable<User> {
    return this.http.put(this.baseUri, user).pipe(
      tap(() => this.snackbar.success('Save successful!', 1000)),
      map((response: User) => {
        return response;
      }),
      catchError((error) => this.handleError(error, 'Error saving user'))
    );
  }

  post(user: User): Observable<User> {
    return this.http.post(this.baseUri, user).pipe(
      tap(() => this.snackbar.success('Save successful!', 1001)),
      map((response: User) => response),
      catchError((error) => this.handleError(error, 'Error saving user'))
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  delete(userId: string): Observable<{}> {
    this.snackbar.wait('Removing...');
    return this.http
      .delete(`${environment.apiUri}/users/${userId}`, {
        params: {
          userId,
        },
      })
      .pipe(
        tap(() => this.snackbar.success('User removed.', 1000)),
        tap(() => this.getAll().subscribe()),
        catchError((error) => this.handleError(error, 'Error removing user'))
      );
  }

  getInviteLink(): Observable<string> {
    return this.http.post(`${environment.apiUri}/users/invites`, {}).pipe(
      map((response: { token: string }) => `${window.location.origin}/invite/${response.token}`),
      catchError((error) => this.handleError(error, 'Error building invite link'))
    );
  }
}
