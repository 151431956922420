import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HotkeysService } from 'web/app/hotkeys.service';

@Component({
  template: '',
})
export class SavePageComponent implements OnInit, OnDestroy {
  private d$ = new Subject<boolean>();

  constructor(protected hotKeys: HotkeysService) {}

  ngOnInit(): void {
    this.hotKeys
      .addShortcut({ keys: 'meta.s' })
      .pipe(takeUntil(this.d$))
      .subscribe(() => {
        this.save();
      });

    this.hotKeys
      .addShortcut({ keys: 'control.s' })
      .pipe(takeUntil(this.d$))
      .subscribe(() => {
        this.save();
      });
  }

  ngOnDestroy(): void {
    this.d$.next(true);
    this.d$.unsubscribe();
  }

  save(): void {
    throw new Error('Not Implemented');
  }
}
