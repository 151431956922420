<mat-form-field appearance="standard">
  <mat-label>Fostered By</mat-label>
  <input
    matInput
    aria-label="Volunteer"
    [matAutocomplete]="auto"
    [formControl]="user"
    [readonly]="disabled"
  />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let u of filteredUsers | async" [value]="u"
      >{{ u.firstName }} {{ u.lastName }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="user?.invalid">Select a volunteer</mat-error>
</mat-form-field>
