import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { DocumentType, FosterDocument } from 'web/app/models/foster-document.model';
import { ConfirmDialogComponent } from 'web/app/shared/components/confirm-dialog/confirm-dialog.component';

import { DocumentDialogComponent } from './document-dialog/document-dialog.component';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsComponent implements OnInit {
  @Input() fosterId: string;
  @Input() documents: FosterDocument[];
  @Input() disabled: boolean;

  @Output() upload = new EventEmitter<any>();
  @Output() download = new EventEmitter<string>();
  @Output() save = new EventEmitter<FosterDocument>();
  @Output() delete = new EventEmitter<string>();

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = ['type', 'name', 'createdAt', 'actions'];
  documentTypes = DocumentType;

  get sortableDocuments() {
    const dataSource = new MatTableDataSource<FosterDocument>();
    dataSource.data = this.documents;
    dataSource.sort = this.sort;
    return dataSource;
  }

  get unknownDocuments() {
    if (!this.documents) {
      return null;
    }
    return this.documents.filter((x) => !x.type && x.type !== 0);
  }

  get groupedDocuments() {
    if (!this.documents) {
      return null;
    }
    const map = new Map();
    this.documents
      .filter((x) => x.type >= 0)
      .forEach((item) => {
        if (item) {
          const key = item.type;
          const collection = map.get(key);
          if (!collection) {
            map.set(key, [item]);
          } else {
            collection.push(item);
          }
        }
      });
    return map;
  }

  constructor(private matDialog: MatDialog) {}

  ngOnInit(): void {
    if (!this.disabled) {
      this.displayedColumns.push('actions');
    }
  }

  confirmCreateDocument(fileEvent: { file: File; document: FosterDocument }) {
    if (!this.documents || !fileEvent || !fileEvent.document) {
      return;
    }
    if (this.documents.find((x) => x.name === fileEvent.document.name)) {
      this.matDialog
        .open(ConfirmDialogComponent, {
          data: {
            text: 'Uploading this file will overwrite the existing file with the same name. Continue anyway?',
          },
        })
        .afterClosed()
        .subscribe((shouldContinue) => {
          if (shouldContinue) {
            this.upload.emit(fileEvent);
          }
        });
    } else {
      this.upload.emit(fileEvent);
    }
  }

  create() {
    this.matDialog
      .open(DocumentDialogComponent, {
        data: {},
        minWidth: '30vw',
        maxWidth: '95%',
        minHeight: '300px',
        panelClass: 'document-upload',
      })
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          this.confirmCreateDocument({ file: response.file, document: response.document });
        }
      });
  }

  edit(doc) {
    this.matDialog
      .open(DocumentDialogComponent, {
        data: {
          document: doc,
          existing: this.documents.filter((x) => x.id !== doc.id),
        },
        minWidth: '300px',
        minHeight: '300px',
        panelClass: 'document-upload',
      })
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          this.save.emit(response.document);
        }
      });
  }

  confirmDelete(doc) {
    this.matDialog
      .open(ConfirmDialogComponent, {
        data: {
          text: `Delete ${doc.name}?`,
          confirmText: 'Delete',
          confirmColor: 'warn',
        },
      })
      .afterClosed()
      .subscribe((shouldContinue) => {
        if (shouldContinue) {
          this.delete.emit(doc.id);
        }
      });
  }
}
