import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { RbSnackbar } from 'web/app/core/rb-snackbar';
import { FosterAdoption } from 'web/app/models/foster-adoption.model';
import { FosterDocument } from 'web/app/models/foster-document.model';
import { Filter, FosterFilter } from 'web/app/models/foster-filter.model';
import { Foster } from 'web/app/models/foster.model';
import { IPage } from 'web/app/models/ipage';
import { environment } from 'web/environments/environment';

import { BaseService } from './base.service';

@Injectable()
export class FosterService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected snackbar: RbSnackbar,
    protected logger: NGXLogger
  ) {
    super(http, snackbar, logger);
    this.baseUri = environment.apiUri + '/foster';
  }

  static copy(foster: Foster): Foster {
    const copy = { ...foster };
    // @ts-expect-error FIXME
    copy.id = null;
    // @ts-expect-error FIXME
    copy.name = null;
    // @ts-expect-error FIXME
    copy.microchipId = null;
    // @ts-expect-error FIXME
    copy.thumbUri = null;
    return copy;
  }

  get(id: string): Observable<Foster> {
    return this.http.get(this.baseUri + '/' + id).pipe(
      map((response: Foster) => response),
      catchError((error) => this.handleError(error, 'Error getting foster info'))
    );
  }

  getAdoptions(id: string): Observable<FosterAdoption[]> {
    return this.http.get(this.baseUri + '/' + id + '/adoptions').pipe(
      map((data: FosterAdoption[]) => data),
      catchError((error) => this.handleError(error, 'Error getting foster adoptions'))
    );
  }

  getAdvancedFilterValue(operands): string {
    if (Array.isArray(operands)) {
      return operands.join(',');
    }
    if (operands instanceof Date) {
      return operands?.toISOString();
    }
    return operands?.toString();
  }

  getAdvancedPage(
    filters: Filter[],
    pageNumber: string,
    pageSize: string,
    search: string
  ): Observable<IPage<Foster>> {
    let params = new HttpParams();

    for (const filter of filters) {
      params = params.append(
        `${filter.field}${filter.operator}`,
        this.getAdvancedFilterValue(filter.operands)
      );
    }

    params = params.append('pageNumber', pageNumber);
    params = params.append('pageSize', pageSize);

    if (search) {
      params = params.append('search', search);
    }

    return this.http.get(this.baseUri + '/advanced', { params }).pipe(
      map((response: IPage<Foster>) => {
        return response;
      }),
      catchError((error) => this.handleError(error, 'Error loading foster info'))
    );
  }

  getSimplePage(search: string, filter: FosterFilter): Observable<IPage<Foster>> {
    let params = new HttpParams();
    params = params.append('pageSize', filter.pageSize.toString());
    params = params.append('pageNumber', filter.pageNumber.toString());
    if (search) {
      params = params.append('search', search);
    }
    if (filter.viewAll) {
      params = params.append('all', filter.viewAll.toString());
    }
    if (filter.sort) {
      params = params.append('sort', filter.sort);
    }
    if (filter.sortDesc) {
      params = params.append('sortDesc', filter.sortDesc.toString());
    }
    if (filter.includeDeceased) {
      params = params.append('deceased', filter.includeDeceased.toString());
    }
    if (filter.adoptableOnly) {
      params = params.append('adoptableOnly', filter.adoptableOnly.toString());
    }
    if (filter.currentlyFostered) {
      params = params.append('currentlyFostered', filter.currentlyFostered.toString());
    }
    if (filter.species) {
      params = params.append('species', filter.species.toString());
    }
    return this.http.get(this.baseUri + '/simple', { params }).pipe(
      map((response: IPage<Foster>) => {
        return response;
      }),
      catchError((error) => this.handleError(error, 'Error loading foster info'))
    );
  }

  uploadImage(id: string, file: File, filePath: string) {
    this.snackbar.wait('Uploading image...');

    const formData = new FormData();
    formData.append('thumb', file, filePath);

    return this.http.post(this.baseUri + '/' + id + '/thumb', formData).pipe(
      tap(() => this.snackbar.success('Upload successful!')),
      map((response: Foster) => {
        return response;
      }),
      catchError((error) => this.handleError(error, 'Error uploading image'))
    );
  }

  getCsv() {
    return this.http.get(this.baseUri + '/csv', { responseType: 'text', observe: 'response' }).pipe(
      map((response: HttpResponse<string>) => ({
        // @ts-expect-error FIXME
        file: new Blob([response.body], { type: 'text/csv' }),
        filename: response.headers.get('File-Name'),
      })),
      catchError((error) => this.handleError(error, 'Error downloading foster export file'))
    );
  }

  getDocuments(id: string): Observable<FosterDocument[]> {
    return this.http.get(this.baseUri + '/' + id + '/document/all').pipe(
      map((data: FosterDocument[]) => data),
      catchError((error) => this.handleError(error, 'Error getting foster documents'))
    );
  }

  getDocument(id: string) {
    return this.http.get(environment.apiUri + '/document/' + id).pipe(
      map((response: any) => response),
      catchError((error) => this.handleError(error, 'Error getting the document'))
    );
  }

  deleteAdoption(id: string, adoptionId: string) {
    return this.http.delete(this.baseUri + '/' + id + '/adoptions/' + adoptionId).pipe(
      tap(() => this.snackbar.success('Adoption deleted')),
      map((response: Foster) => response),
      catchError((error) => this.handleError(error, 'Error deleting adoption'))
    );
  }

  deleteDocument(fosterId, id: string) {
    this.snackbar.wait('Deleting...');
    return this.http.delete(environment.apiUri + '/document/' + id).pipe(
      tap(() => this.snackbar.success('Document deleted')),
      catchError((error) => this.handleError(error, 'Error deleting document'))
    );
  }

  updateDocument(fosterId: string, doc: FosterDocument): Observable<FosterDocument> {
    this.snackbar.wait('Saving...');
    return this.http.put(environment.apiUri + '/document/' + doc.id, doc).pipe(
      tap(() => this.snackbar.success('Update successful!')),
      map((response: FosterDocument) => {
        return response;
      }),
      catchError((error) => this.handleError(error, 'Error updating document'))
    );
  }

  createDocument(
    id: string,
    file: File,
    document: FosterDocument
  ): Observable<FosterDocument> | undefined {
    if (!id || !file || !document) {
      return;
    }

    this.snackbar.wait('Uploading file...');

    const formData = new FormData();
    formData.append('document', JSON.stringify(document));
    formData.append('file', file, document.name);

    return this.http.post(this.baseUri + '/' + id + '/document', formData).pipe(
      tap(() => this.snackbar.success('Upload successful!')),
      map((response: FosterDocument) => {
        return response;
      }),
      catchError((error) => this.handleError(error, 'Error uploading file'))
    );
  }

  save(foster: Foster): Observable<Foster> {
    if (foster.id) {
      return this.put(foster);
    }
    return this.post(foster);
  }

  saveAdoption(fosterId: string, adoption: FosterAdoption): Observable<FosterAdoption> {
    if (adoption.id) {
      return this.putAdoption(fosterId, adoption);
    }
    return this.postAdoption(fosterId, adoption);
  }

  delete(foster: Foster): Observable<Foster> {
    this.snackbar.wait('Deleting...');
    return this.http.delete(this.baseUri + '/' + foster.id).pipe(
      tap(() => this.snackbar.success('Deleted!', 1000)),
      map((response: Foster) => {
        return response;
      }),
      catchError((error) => this.handleError(error, 'Error deleting foster'))
    );
  }

  put(foster: Foster): Observable<Foster> {
    return this.http.put(this.baseUri, foster).pipe(
      map((response: Foster) => {
        return response;
      }),
      catchError((error) => this.handleError(error, 'Error saving foster info'))
    );
  }

  putAdoption(id: string, adoption: FosterAdoption): Observable<FosterAdoption> {
    return this.http.put(this.baseUri + '/' + id + '/adoptions/' + adoption.id, adoption).pipe(
      tap(() => this.snackbar.success('Updated adoption record!')),
      map((response: FosterAdoption) => {
        return response;
      }),
      catchError((error) => this.handleError(error, 'Error saving foster adoption info'))
    );
  }

  post(foster: Foster): Observable<Foster> {
    return this.http.post(this.baseUri, foster).pipe(
      map((response: Foster) => {
        return response;
      }),
      catchError((error) => this.handleError(error, 'Error saving foster info'))
    );
  }

  postAdoption(id: string, adoption: FosterAdoption): Observable<FosterAdoption> {
    return this.http.post(this.baseUri + '/' + id + '/adoptions', adoption).pipe(
      tap(() => this.snackbar.success('Added adoption record!')),
      map((response: FosterAdoption) => {
        return response;
      }),
      catchError((error) => this.handleError(error, 'Error saving foster adoption info'))
    );
  }

  getCoverSheetUrl(foster: Foster, fileName: string) {
    return `${this.baseUri}/${foster.id}/${fileName}`;
  }

  getCoverSheet(foster: Foster, fileName: string): Observable<Blob> {
    return this.http.get(`${this.baseUri}/${foster.id}/${fileName}`, {
      responseType: 'blob',
    });
  }

  doBulkUpdate(updates: Foster) {
    this.snackbar.wait('Saving...');

    return this.http.put(this.baseUri + '/bulk', updates).pipe(
      tap(() => this.snackbar.success('Saved!')),
      map((response: Foster) => {
        return response;
      }),
      catchError((error) => this.handleError(error, 'Error saving updates.'))
    );
  }

  processVaccinations(id: string) {
    return this.http.put(`${this.baseUri}/${id}/processVaccinations`, {}).pipe(
      map((response: Foster) => {
        return response;
      }),
      catchError((error) => this.handleError(error, 'Error processing vaccinations'))
    );
  }
}
