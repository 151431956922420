import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  template: `
    <div fxLayoutAlign="space-around center">
      <div class="loading-container">
        <mat-spinner></mat-spinner>
        <div>Loading...</div>
      </div>
    </div>
  `,
  styles: [
    `
      .loading-container {
        margin-top: 30vh;
        text-align: center;
      }
    `,
    `
      mat-spinner {
        margin-bottom: 20px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSpinnerComponent {}
