import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { decode } from 'blurhash';

import { FosterService } from 'web/app/core/services/foster.service';
import { Foster, Species } from 'web/app/models/foster.model';

const blurhashWidthHeight = 40;
@Component({
  selector: 'app-foster-tile',
  templateUrl: './foster-tile.component.html',
  styleUrls: ['./foster-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FosterTileComponent implements OnChanges {
  @Input() foster: Foster;
  @Input() showUserName: boolean;
  @Input() disableClick: boolean;
  @Input() canSelect: boolean;
  @Input() selectedFosters: Foster[];
  @Output() select = new EventEmitter<void>();

  get selected(): boolean {
    return this.selectedFosters?.some((x) => x.id === this.foster?.id);
  }

  species = Species;
  baseUri: string;
  imageLoaded = false;
  blurhashUrl: string;

  constructor(private fosterService: FosterService) {
    this.baseUri = this.fosterService.baseUri;
  }

  thumbnailLoaded(): void {
    this.imageLoaded = true;
  }

  handleSelect(event): void {
    event.stopPropagation();
    this.select.emit();
  }

  ngOnChanges(changes): void {
    if (changes.foster) {
      if (!this.foster.blurhash) {
        return;
      }

      const pixels = decode(this.foster.blurhash, blurhashWidthHeight, blurhashWidthHeight);

      const canvas = document.createElement('canvas');
      canvas.width = blurhashWidthHeight;
      canvas.height = blurhashWidthHeight;
      const context = canvas.getContext('2d');
      const imageData = context?.createImageData(
        blurhashWidthHeight,
        blurhashWidthHeight
      ) as ImageData;
      imageData.data.set(pixels);
      context?.putImageData(imageData, 0, 0);

      this.blurhashUrl = canvas.toDataURL();
    }
  }
}
