import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { RbSnackbar } from 'web/app/core/rb-snackbar';
import { UserService } from 'web/app/core/services/user.service';
import { User } from 'web/app/models/user.model';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSelectComponent implements OnChanges {
  @Input() form: FormGroup;
  @Input() disabled = false;

  users: User[];
  loadingUsers: boolean;
  filteredUsers: Observable<User[]>;

  get user(): AbstractControl | null {
    return this.form?.get('user');
  }

  displayFn(user?: User): string | undefined {
    return user ? `${user.firstName} ${user.lastName}` : undefined;
  }

  constructor(private userService: UserService, private snackbar: RbSnackbar) {
    this.loadingUsers = true;
    this.userService.getAll().subscribe(
      (users) => {
        this.loadingUsers = false;
        this.users = users;
      },
      () => {
        this.loadingUsers = false;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && this.user) {
      this.filteredUsers = this.user.valueChanges.pipe(
        startWith<string | User>(''),
        map((value) =>
          // eslint-disable-next-line no-nested-ternary
          !value ? null : typeof value === 'string' ? value : `${value.firstName} ${value.lastName}`
        ),
        map((value) => (value ? this.filterUsers(value) : this.users))
      );
    }
  }

  private filterUsers(name: string): User[] {
    const filterValue = name.toLowerCase();
    if (!this.users) {
      return [];
    }
    return this.users.filter(
      (user) => user && `${user.firstName} ${user.lastName}`.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  notifyCopied(): void {
    this.snackbar.success('Email copied!');
  }
}
