export class FosterDocument {
  id: string;
  name: string;
  type: DocumentType;
  createdAt: Date;
  isScan: boolean;

  constructor(name: string) {
    this.name = name;
  }
}

export enum DocumentType {
  Adoption_Contract = 0,
  Medical_Record = 1,
  Intake_Document = 2,
  Owner_Surrender_Form = 3,
  Trial_Run_Agreement = 4,
}
